import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Favorites } from '../data-object/Favorites';
import { Filter } from '../data-object/Filter';
import { Router } from '@angular/router';
import { ErrorHandlingServiceService } from './error-handling-service.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlingService : ErrorHandlingServiceService,
  ) { }

    private Url = environment.httpText + environment.apiServer + environment.apiText + 'favorites';

    postFavoritesCompany(datumId : string) { // 관심 기업 데이터 추가
      var url = `${this.Url}/company`;
      let parameter = {
        datumId: datumId
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /favorites/company`) )
      );
    }

    getFavoritesCompanyDatumId(datumId : string) { // 관심 기업 데이터 여부 조회
      var url = `${this.Url}/company/${datumId}`;

      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /favorites/company/${datumId}`) )
      );
    }

    deleteFavoritesCompanyDatumId(datumId : string){ // 관심 기업 데이터 삭제
      var url = `${this.Url}/company/${datumId}`;

      return this.http.delete( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`DELETE /favorites/company/${datumId}`) )
      );
    }

    postFavoritesPublic(datumId : string) { // 관심 공공 데이터 추가
      var url = `${this.Url}/public`;
      let parameter = {
        datumId: datumId
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /favorites/public`) )
      );
    }

    getFavoritesPublicDatumId(datumId : string) { // 관심 공공 데이터 여부 조회
      var url = `${this.Url}/public/${datumId}`;

      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /favorites/public/${datumId}`) )
      );
    }

    deleteFavoritesPublicDatumId(datumId : string){ // 관심 기업 데이터 삭제
      var url = `${this.Url}/public/${datumId}`;

      return this.http.delete( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`DELETE /favorites/public/${datumId}`) )
      );
    }

    getFavoritesResearchdata(filter : Filter) : Observable<Favorites>{ // 
      var url = `${this.Url}/researchdata`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc)
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc)
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.largeCategoryCode != null && filter.largeCategoryCode != ''){
        httpParams =  httpParams.set('largeCategoryCode', filter.largeCategoryCode)
      }

      if(filter.mediumCategoryCode != null && filter.mediumCategoryCode != ''){
        httpParams =  httpParams.set('mediumCategoryCode', filter.mediumCategoryCode)
      }
  
      return this.http.get<Favorites>( url, {params : httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<Favorites>(`GET /favorites/researchdata`) )
      );
    }

    postFavoritesResearchdata(categoryCode : string){ // 
      var url = `${this.Url}/researchdata`;
      let parameter = {
        categoryCode: categoryCode
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /favorites/researchdata`) )
      );
    }

    postFavoritesResearchCorporatedata(datumId : string){ // 
      let url = `${this.Url}/company`;
      let parameter = {
        datumId: datumId
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /favorites/company`) )
      );
    }

    postUnSetFavoritesResearchCorporatedata(datumId : string){ // 
      let url = `${this.Url}/company/${datumId}`;
      return this.http.delete( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`DELETE /favorites/company`) )
      );
    }

    postFavoritesResearchPublicdata(datumId : string){ // 
      let url = `${this.Url}/public`;
      let parameter = {
        datumId: datumId
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /favorites/public`) )
      );
    }

    postUnSetFavoritesResearchPublicdata(datumId : string){ // 
      let url = `${this.Url}/public/${datumId}`;
      return this.http.delete( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`DELETE /favorites/public`) )
      );
    }

    getFavoritesResearchdataCategory(categoryCode : string){ // 
      var url = `${this.Url}/researchdata/${categoryCode}`;
      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /favorites/researchdata/${categoryCode}`) )
      );
    }

    deleteFavoritesResearchdataCategory(categoryCode : string){ // 
      var url = `${this.Url}/researchdata/${categoryCode}`;

      return this.http.delete( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`DELETE /favorites`) )
      );
    }

    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      
      // HTTP 에러 상태를 체크합니다.
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // 401 에러일 경우 로그인 페이지로 리다이렉트합니다.
          alert("장시간 동작이 없어 자동으로 로그아웃 되었습니다.");
          this.router.navigate(['/auth/login']);
        }
      }
      
      return of(result as T);
      };
    }

}
