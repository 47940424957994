<ng-template #buyData let-modal>
    <div class="modal-header">
        데이터 구매
        <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="">
          <div class="row" style="padding: 50px;">
            <div class="col-md-12">
              <div>
                <div class="select-list-box"> 대분류 : {{mainCategory.displayName}}</div>
                <div class="select-list-box"> 중분류 : {{middleCategory.displayName}}</div>
              </div>
              <div style="margin-bottom: 20px;">
                <span style="padding: 20px;" *ngIf="purchasesInfo.providers.length != 0">구매 가격 : {{purchasesInfo.price}} 원</span>
              </div>
              <div>
                <span style="padding: 20px;" *ngIf="purchasesInfo.providers.length != 0">데이터 제공자 : {{purchasesInfo.providers[0].providerName}}</span>
              </div>
              <div style="padding: 20px; color: red; font-weight: bold;" *ngIf="purchasesInfo.providers.length == 0" > 해당 카테고리에 데이터를 제공한 기관이 아직 없습니다.</div>
            </div>
          </div>

          
          <div class="row"> 
            <div class = "email-box">
              <input nz-input type="text" [(ngModel)]="email" placeholder="E-Mail" />
            </div>
            
          </div>

    
          <button nz-button style="float : right; margin-left: 10px;" nzShape="round" (click)="modal.dismiss('Cross click')">취소</button>
          <button nz-button nzType="primary" style="float : right; " nzShape="round" (click)="setBuyData()" [disabled]="purchasesInfo.providers.length == 0">데이터 구매 신청</button>


        </form>
      </div>
</ng-template>