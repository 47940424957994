import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../data-object/Filter';
import { Router } from '@angular/router';
import { ErrorHandlingServiceService } from './error-handling-service.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CorellationsService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlingService : ErrorHandlingServiceService,
  ) { }
  
    private Url = environment.httpText + environment.apiServer + environment.apiText + 'corellations';

    
    getCorellations(filter : Filter){ // 상관관계 분석 목록 조회
      var url = `${this.Url}`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc)
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc)
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.name != null){
        httpParams = httpParams.set('name', filter.name)
      }

      if(filter.subjectIdA != null){
        httpParams = httpParams.append('subjectId', filter.subjectIdA);
      }

      if(filter.subjectIdB != null){
        httpParams = httpParams.append('subjectId', filter.subjectIdB)
      }

      console.log(url)
      return this.http.get( url ,{params : httpParams}).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /corellations`) )
      );
    }

    getCalculateCorellation(subjectA : any, subjectB : any){ // 상관관계 분석 수행
      var url = `${this.Url}/analysis`;
      let httpParams = new HttpParams()
      .set("subjectA",subjectA.subjectId)
      .set("subjectB",subjectB.subjectId)
  
      return this.http.get( url, {params : httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /corellations/analysis`) )
      );
    }

    getCorellationsHistory(corellationId : string){ // 상관관계 분석 내역 조회
      var url = `${this.Url}/history/${corellationId}`;

      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /corellations/history/{corellationId}`) )
      );
    }

    deleteCorellations(corellationId : string){ // 상관관계 분석 내역삭제
      var url = `${this.Url}/${corellationId}`;

      return this.http.delete( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /corellations/{corellationId}`) )
      );
    }

    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      // HTTP 에러 상태를 체크합니다.
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // 401 에러일 경우 로그인 페이지로 리다이렉트합니다.
          alert("장시간 동작이 없어 자동으로 로그아웃 되었습니다.");
          this.router.navigate(['/auth/login']);
        }
      }
      return of(result as T);
      };
    }

}
