import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../data-object/Filter';
import { PurchasesCategoriesSummary } from '../data-object/PurchasesCategoriesSummary';
import { Router } from '@angular/router';
import { ErrorHandlingServiceService } from './error-handling-service.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PurchasesService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlingService : ErrorHandlingServiceService,
  ) { }

    private Url = environment.httpText + environment.apiServer + environment.apiText + 'purchases';

    getPurchasesCompanydata(filter : Filter) { // 기업 데이터 구매 및 판매 목록
      var url = `${this.Url}/companydata`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc)
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc)
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.begin != null){
        httpParams = httpParams.set('begin', filter.begin)
      }
  
      if(filter.end != null){
        httpParams = httpParams.set('end', filter.end)
      }

      if(filter.listType != null){
        httpParams = httpParams.set('listType', filter.listType)
      }
  
      return this.http.get( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /purchases/companydata`) )
      );
    }

    postPurchasesCompanydata( datumId : string, email : string ){ // 기업 데이터 구매 신청
      var url = `${this.Url}/companydata`;
      let parameter = {
        datumId : datumId,
        email : email
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /purchases/companydata`) )
      );
    }

    patchPurchasesCompanydata( userId : string, datumId : string,  ){ // 기업 데이터 구매 승인
      let url = `${this.Url}/companydata`;
      let parameter = {
        userId : userId,
        datumId : datumId
      }

      return this.http.patch<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /purchases/companydata`) )
      );
    }

    getPurchaseResearchdata(filter : Filter) : Observable<PurchasesCategoriesSummary>{ // (사용자,제공자,관리자)구매 목록 조회
      var url = `${this.Url}/researchdata`;
      let httpParams = new HttpParams()

/*       if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc)
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc)
      } */

      if(filter.ascAry != null && filter.ascAry.length != 0 ){
        for(let i = 0; i < filter.ascAry.length; i++){
          httpParams = httpParams.append('asc', filter.ascAry[i])
        }
      }

      if(filter.descAry != null && filter.descAry.length != 0 ){
        for(let i = 0; i < filter.descAry.length; i++){
          httpParams = httpParams.append('desc', filter.descAry[i])
        }
      }

      if(filter.status != null && filter.status.length != 0 ){
        for(let i = 0; i < filter.status.length; i++){
          httpParams = httpParams.append('status', filter.status[i])
        }
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.begin != null){
        httpParams = httpParams.set('begin', filter.begin)
      }
  
      if(filter.end != null){
        httpParams = httpParams.set('end', filter.end)
      }

      if(filter.userId != null){
        httpParams = httpParams.set('userId', filter.userId)
      }

      if(filter.userName != null && filter.userName != ''){
        httpParams = httpParams.set('userName', filter.userName)
      }

      if(filter.providerId != null){
        httpParams = httpParams.set('providerId', filter.providerId)
      }

      if(filter.providerName != null && filter.providerName != ''){
        httpParams = httpParams.set('providerName', filter.providerName)
      }

      if(filter.largeCategoryCode != null && filter.largeCategoryCode != ''){
        httpParams = httpParams.set('largeCategoryCode', filter.largeCategoryCode)
      }

      if(filter.mediumCategoryCode != null && filter.mediumCategoryCode != ''){
        httpParams = httpParams.set('mediumCategoryCode', filter.mediumCategoryCode)
      }

      if(filter.listType != null){
        httpParams = httpParams.set('listType', filter.listType)
      }
      
      return this.http.get<PurchasesCategoriesSummary>( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<PurchasesCategoriesSummary>(`GET /purchases/users`) )
      );
    }

    getPurchaseCorporatedata(filter : Filter) : Observable<PurchasesCategoriesSummary>{ // (사용자, 제공자, 관리자) 기업 데이터 구매 및 판매 목록
      var url = `${this.Url}/companydata`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc)
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc)
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.begin != null){
        httpParams = httpParams.set('begin', filter.begin)
      }
  
      if(filter.end != null){
        httpParams = httpParams.set('end', filter.end)
      }
        
      if(filter.listType != null){
        httpParams = httpParams.set('listType', filter.listType)
      }

/*       if(filter.userId != null){
        httpParams = httpParams.set('userId', filter.userId)
      }

      if(filter.userName != null && filter.userName != ''){
        httpParams = httpParams.set('userName', filter.userName)
      }

      if(filter.providerId != null){
        httpParams = httpParams.set('providerId', filter.providerId)
      } */

/*       if(filter.providerName != null && filter.providerName != ''){
        httpParams = httpParams.set('providerName', filter.providerName)
      } */

      /* if(filter.largeCategoryCode != null && filter.largeCategoryCode != ''){
        httpParams = httpParams.set('largeCategoryCode', filter.largeCategoryCode)
      }

      if(filter.mediumCategoryCode != null && filter.mediumCategoryCode != ''){
        httpParams = httpParams.set('mediumCategoryCode', filter.mediumCategoryCode)
      } */
      
      return this.http.get<PurchasesCategoriesSummary>( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<PurchasesCategoriesSummary>(`GET /purchases/users`) )
      );
    }

    postPurchaseResearchdata(categoryCode : string, purchasesInfo : any, email : string ){ // (사용자)구매
      var url = `${this.Url}/researchdata`;
      let parameter = {
        providerId: purchasesInfo.providers[0].providerId,
        categoryCode: categoryCode,
        email: email,
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /purchases`) )
      );
    }


    patchPurchaseResearchdata(userId : string, categoryCode : any){ // 개인맞춤데이터 데이터 구매 승인
      var url = `${this.Url}/researchdata`;
      let parameter = {
        userId: userId,
        categoryCode: categoryCode
      }
  
      return this.http.patch( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`patch /purchases`) )
      );
    }


    getPurchasesResearchdataCategoryCategoryCode(categoryCode : string){ // (사용자)구매하려는 카테고리의 정보
      var url = `${this.Url}/researchdata/category/${categoryCode}`;

      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /purchases/researchdata/category/${categoryCode}`) )
      );
    }


    getPurchasesResearchdataSales(filter : Filter){ // (제공자,관리자)판매 목록 조회
      let url = `${this.Url}/researchdata`;
      let httpParams = new HttpParams()

/*       if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc)
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc)
      } */

      if(filter.ascAry != null && filter.ascAry.length != 0 ){
        for(let i = 0; i < filter.ascAry.length; i++){
          httpParams = httpParams.append('asc', filter.ascAry[i])
        }
      }

      if(filter.descAry != null && filter.descAry.length != 0 ){
        for(let i = 0; i < filter.descAry.length; i++){
          httpParams = httpParams.append('desc', filter.descAry[i])
        }
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.largeCategoryCode != null && filter.largeCategoryCode != ''){
        httpParams = httpParams.set('largeCategoryCode', filter.largeCategoryCode)
      }
      
      if(filter.mediumCategoryCode != null && filter.mediumCategoryCode != ''){
        httpParams = httpParams.set('mediumCategoryCode', filter.mediumCategoryCode)
      }

      if(filter.createdUserId != null){
        httpParams = httpParams.set('createdUserId', filter.createdUserId)
      }

      if(filter.status != null && filter.status.length != 0 ){
        for(let i = 0; i < filter.status.length; i++){
          httpParams = httpParams.append('status', filter.status[i])
        }
      }

      if(filter.listType != null){
        httpParams = httpParams.set('listType', filter.listType)
      }

      return this.http.get( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /purchases/researchdata/sales`) )
      );
    }

    postResearchdataSales(categoryCode : string, assignedPrice : number, comment : string){ // (제공자)판매 신청  ---- 테스트 필요 ------
      var url = `${this.Url}/researchdata/sales`;
      let parameter = {
        categoryCode : categoryCode,
        assignedPrice : assignedPrice,
        comment : comment
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /purchases/researchdata/sales`) )
      );
    }

    getPurchasesResearchdataSalesSalesId(salesId : string){ // (제공자,관리자)판패 신청 정보 조회 --- 테스트 필요 ( 어디서 쓰는거지 ) ----
      var url = `${this.Url}/researchdata/sales/${salesId}`;
      let httpParams = new HttpParams()


      return this.http.get( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /purchases/researchdata/sales/${salesId}`) )
      );
    }


    putPurchasesResearchdataSalesSalesId(categoryCode : string, assignedPrice : number, comment : string, salesId : string){ // (제공자)반려된 판매 신청 정보 수정 --- 테스트 필요 ----
      var url = `${this.Url}/researchdata/sales/${salesId}`;
      let parameter = {
        categoryCode : categoryCode,
        assignedPrice : assignedPrice,
        comment : comment
      }
  
      return this.http.put( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /purchases/researchdata/sales/${salesId}`) )
      );
    }

    patchPurchasesResearchdataSalesSalesId(parameter : any, salesId : string){ // (관리자)판매 승인/반려 --- 테스트 필요 ---
      var url = `${this.Url}/researchdata/sales/${salesId}`;
      
      return this.http.patch( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`PATCH /purchases/researchdata/sales/${salesId}`) )
      );
    }

    getPurchasesResearchdataPurchaseId(purchaseId : string){ // (사용자)구매 기록 상세 조회
      var url = `${this.Url}/researchdata/sales/${purchaseId}`;

      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /purchases/researchdata/sales/${purchaseId}`) )
      );
    }

    dataBuy() {
      
    }


    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      
      // HTTP 에러 상태를 체크합니다.
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // 401 에러일 경우 로그인 페이지로 리다이렉트합니다.
          alert("장시간 동작이 없어 자동으로 로그아웃 되었습니다.");
          this.router.navigate(['/auth/login']);
        }
      }
      
      return of(result as T);
      };
    }
}
