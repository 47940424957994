<ng-template #dataSearch let-modal>
    <div class="modal-header" style="padding: 10px 20px;">
        
        <div class="select-list-box" (click)="resetSelect()"> Home </div>
        <div class="select-list-box" *ngIf="mainCategorySelection != undefined">/</div>
        <div class="select-list-box" (click)="selectMainCategory(mainCategorySelection)" *ngIf="mainCategorySelection != undefined">{{mainCategorySelection.displayName}}</div>
        <div class="select-list-box" *ngIf="middleCategorySelect != undefined">/</div>
        <div class="select-list-box" (click)="selectMiddleCategory(middleCategorySelect)" *ngIf="middleCategorySelect != undefined">{{middleCategorySelect.displayName}}</div>
    
        <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">


        <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="">
              
          <div class="row">
            <ng-container *ngIf="mainCategorySelection == undefined">
              <div class="col-sm-6 col-xl-3 col-lg-6 " *ngFor="let category of mainCategoryData.subCategories">
                <div class="card o-hidden" (click)="selectMainCategory(category)" style="cursor: pointer;">
                  <div class="bg-primary b-r-4 card-body" style="padding: 20px;">
                    <div class="media static-top-widget">

                      <div class="align-self-center text-center" style="width: 30px;height: 30px;">
                        <app-feather-icons [icon]="'folder'"></app-feather-icons>
                      </div>
                      <div class="media-body"><span class="m-0">{{category.displayName}}</span>
                        <i class="icon-bg" data-feather="folder"></i>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="mainCategorySelection != undefined && middleCategorySelect == undefined">
                <div class="col-sm-6 col-xl-3 col-lg-6 " *ngFor="let category of middleCategoryData.subCategories">
                  <div class="card o-hidden" (click)="selectMiddleCategory(category)" style="cursor: pointer;">
                    <div class="bg-primary b-r-4 card-body" style="padding: 20px;">
                      <div class="media static-top-widget">
                        

                        <div class="align-self-center text-center" style="width: 30px;height: 30px;">
                          <app-feather-icons [icon]="'folder'"></app-feather-icons>
                        </div>
                        <div class="media-body"><span class="m-0">{{category.displayName}}</span>
                          <i class="icon-bg" data-feather="folder"></i>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
            </ng-container>

            <ng-container *ngIf="mainCategorySelection != undefined && middleCategorySelect != undefined">
              <div class="col-md-12">
                <input style="margin: 5px 0;" type="text" placeholder="Search" class="form-control" [(ngModel)]="filter.subjectName" (ngModelChange)="this.getCategorySubject()"/>

                <table class="table">
                  <thead>
                      <tr>
                        <th style="text-align: start;">소분류 항목</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let categories of subCategory.categories" (click)="clickSubCategory(categories)" [ngClass]="{'selectRow': selectCategory == categories}" (dblclick)="setCategory()" style="cursor: pointer;">
                        
                          <td scope="row" style="display: flex; text-align: start;">
                            <div style="width: 25px;"></div> 
                            
                            <div style="width: 90%;">
                              {{categories.displayName}}

                            </div>
                          
                            <div style="width: calc(10% - 25px);">
                              {{categories.valueType}}
                            </div>
                          </td>
                      </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-center p-2">
                  <nz-pagination 
                    nzShowSizeChanger
                    [nzTotal]="subCategory.count"
                    [(nzPageIndex)]="page"
                    [(nzPageSize)]="getsetPageSize">
                  </nz-pagination>
                </div>
                
              </div>
            </ng-container>
          </div>
    
          <!--<button style="float : right; " class="btn gray" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">닫기</button>
          <button style="float : right; margin-right: 10px;" class="btn btn-primary" type="button" data-dismiss="modal"  (click)="setCategory()">설정</button>-->
        </form>
      </div>
</ng-template>