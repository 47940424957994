import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { Filter } from 'src/app/shared/data-object/Filter';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-data-search',
  templateUrl: './data-search.component.html',
  styleUrls: ['./data-search.component.scss']
})
export class DataSearchComponent implements OnInit {
  @ViewChild("dataSearch", { static: false }) dataSearch: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  @Output() category = new EventEmitter<any>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private metadataService : MetadataService,
    private utilService : UtilService
  ) { }

  ngOnInit(): void {
  }
  
  mainCategorySelection : any;
  middleCategorySelect : any;

  currentDate : Date = new Date();
 
  mainCategoryData : any = { }

  middleCategoryData : any = { }

  subCategory : any = {
    count : 0
  }

  currentPage = 1;
  pageSize : number = 10;
  filter : Filter = new Filter()

  get page(){return this.currentPage}
  set page(page:number){
    console.log(page)
    this.currentPage = page
    this.filter.offset = ((this.currentPage-1) * this.pageSize)
    this.getCategorySubject()
  }

  get getsetPageSize(){return this.pageSize}
  set getsetPageSize(pageSize:number){
    this.pageSize = pageSize
    this.filter.offset = ((this.currentPage-1) * this.pageSize)
    this.getCategorySubject()
  }

  selectCategory : any = null

  direction : string = ""

  openModal(direction) {
    this.direction = direction
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.dataSearch, { 
        size: 'xl',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'test'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    this.resetSelect()
    this.filter = new Filter()
    this.currentPage = 1

    this.metadataService.getCategorie(new Filter(), "ROOT").subscribe(result=>{
      this.mainCategoryData = result
    })
  }

  getCategorySubject(){
    this.filter.limit = 10
    this.metadataService.getCategorySubject(this.filter, this.middleCategorySelect.categoryCode).subscribe(result=>{
      console.log(result)
      this.subCategory = result
    })
  }

  clickSubCategory(category){
    console.log(category)
    this.selectCategory = category
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  resetSelect(){
    this.selectCategory = null
    this.mainCategorySelection = undefined
    this.middleCategorySelect = undefined
  }

  selectMainCategory(category){
    this.selectCategory = null
    this.middleCategorySelect = undefined
    this.mainCategorySelection = category

    this.metadataService.getCategorie(new Filter(), category.categoryCode).subscribe(result=>{
      this.middleCategoryData = result
    })
  }

  selectMiddleCategory(category){
    this.selectCategory = null
    this.middleCategorySelect = category
    this.page = 1
  }

  setCategory(){
    if(this.selectCategory == null){
      this.utilService.openPopup("상관관계 분석","소분류를 선택해 주세요.","info")
      return
    }

    if(this.selectCategory.valueType == "STRING"){
      this.utilService.openPopup("상관관계 분석", "STRING으로 구성된 데이터는 상관관계 분석에 사용될 수 없습니다.","info")
      return
    }

    if(this.selectCategory.valueType == "BOOLEAN"){
      this.utilService.openPopup("상관관계 분석", "BOOLEAN으로 구성된 데이터는 상관관계 분석에 사용될 수 없습니다.","info")
      return
    }

    console.log()

    this.category.emit({
      direction : this.direction,
      mainCategory : this.mainCategorySelection,
      middleCategory : this.middleCategorySelect,
      value : this.selectCategory

    })
    this.modalService.dismissAll()
  }
}
