import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PasswordForgotComponent } from 'src/app/shared/modal/password-forgot/password-forgot.component';
import { ResetPasswordComponent } from 'src/app/shared/modal/reset-password/reset-password.component';
import { SignUpComponent } from 'src/app/shared/modal/sign-up/sign-up.component';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  /* rememberPasswordCheck = localStorage.getItem('userpwCheck'); */
  pw = "";
  @ViewChild("signUpModal") signUpModal: SignUpComponent;
  @ViewChild("passwordForgot") passwordForgot: PasswordForgotComponent;
  @ViewChild("resetPassword") resetPassword: ResetPasswordComponent;
  
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  // authService: any;

  // public authService: AuthService,
  constructor(private fb: FormBuilder,public authService: AuthService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService : UserService,
    private utilService : UtilService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

/*  root: manager@suredatalab.com
  user: user@welcare.org
  provider: provider@welcare.org*/

  loginErrorMessage$ : Subscription;

  ngAfterViewInit(): void {
    let paramsObject : any
    let token
    this.activeRoute.queryParamMap.subscribe((params) => {
      paramsObject = { ...params.keys, ...params };
      token = paramsObject.params.token
    });
    console.log(token)
    if(token){
      this.resetPassword.openModal(token)
    }
    /* console.log(localStorage.getItem('userpw'));
    this.pw = localStorage.getItem('userpw'); */
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.loginErrorMessage$)this.loginErrorMessage$.unsubscribe()
  }

  ngOnInit() {
    this.loginErrorMessage$ = this.authService.loginErrorMessage$.subscribe(errorMessage =>{
      this.errorMessage = errorMessage
    })
  }

  showPassword() {
    this.show = !this.show;
  }

  // Simple Login
  login() {
    console.log("login")
    this.authService.getLoginUrl().subscribe(result=>{
      if(result){
        console.log(result)
        this.authService.SignIn(result, this.loginForm.value['email'], this.loginForm.value['password']).subscribe(
          res=>{
            console.log(res)
            this.userService.getMyProfile().subscribe(
              res=>{
                localStorage.setItem('user', res.body);
                console.log(res)
                localStorage.setItem('user', JSON.stringify(res.body));
                /* if( this.rememberPasswordCheck ){
                  localStorage.setItem('userpwCheck',this.rememberPasswordCheck);
                  localStorage.setItem('userpw',this.pw);
                }else{
                  localStorage.removeItem('userpw');
                  localStorage.removeItem('userpwCheck');
                } */
                if(res.body.authorityId == "root" || res.body.authorityId == "admin"){
                  this.router.navigate(['/dashboard/manager']);
                }else if(res.body.authorityId == "provider"){
                  this.router.navigate(['/dashboard/producer']);
                }else if(res.body.authorityId == "user"){
                  this.router.navigate(['/dashboard/guest']);
                }else if(res.body.authorityId == "reviewer"){
                  this.router.navigate(['/judge']);
                }
              },error=>{
                console.log(error)
                console.log(error.headers.get('grpc-message'))
                this.router.navigate(['/acknowledgment_error']);
            })
          },
          error=>{
            console.log(error)
            this.errorMessage = "ID와 Password를 확인해 주세요."
          })
      }
    })
  }

  signUp(){
    this.router.navigate(['/sign_up'])
  }
  
  forgetPassword(){
    this.passwordForgot.openModal()
  }
}
