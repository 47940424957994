import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { User } from '../../data-object/User';
import { Contact } from '../../data-object/Contact';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

  @Output() passwordChangeOpen = new EventEmitter<any>();
  @ViewChild("userAccount", { static: false }) userAccount: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private userService : UserService,
    private utilService : UtilService
  ) { }

  user : User = new User()

  ngOnInit(): void {
  }

  phoneNumberFirst : string = ""
  phoneNumberSecond : string = ""
  phoneNumberThird : string = ""

  checkName : boolean = false;
  checkSurName : boolean = false
  checkPhoneNumber : boolean = false;
  checkAuthority : boolean = false;

  isUserInformation : boolean = false

  openModal(user) {
    console.log(this.user)
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.userAccount, { 
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    if(user == null){
      this.isUserInformation = false
      this.userService.getMyProfile().subscribe(result=>{
        console.log(result)
        if(result){
          this.user = result.body
  
          if(result.body.contact.shortCode){
            this.phoneNumberFirst = result.body.contact.shortCode.number.split('-', 3)[0]
            this.phoneNumberSecond = result.body.contact.shortCode.number.split('-', 3)[1]
            this.phoneNumberThird  = result.body.contact.shortCode.number.split('-', 3)[2]
          }
        }
      })
    }else {
      this.isUserInformation = true
      this.userService.getUserInformation(user.userId).subscribe(result=>{
        console.log(result)
        if(result){
          this.user = result
  
          if(result.contact.shortCode){
            this.phoneNumberFirst = result.contact.shortCode.number.split('-', 3)[0]
            this.phoneNumberSecond = result.contact.shortCode.number.split('-', 3)[1]
            this.phoneNumberThird  = result.contact.shortCode.number.split('-', 3)[2]
          }
        }
      })
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openChagnePassword(){
    this.passwordChangeOpen.emit(true)
    //this.chagnePassword.openModal()
  }

  updateMyAccount(){
    console.log("updateMyAccount")

    if(this.user.username == ""){
      this.checkName = true
      return
    }else {
      this.checkName = false
    }

    if(this.user.surname == ""){
      this.checkSurName = true;
      return
    }else {
      this.checkSurName = false;
    }

    if(this.phoneNumberFirst == "" || this.phoneNumberSecond.length < 4 || this.phoneNumberThird.length < 4){
      this.checkPhoneNumber = true
      return
    }else {
      this.checkPhoneNumber = false
    }

    let updateUser : any = {};
    updateUser.email = this.user.email
    updateUser.username = this.user.username
    updateUser.surname = this.user.surname;
    updateUser.uid = this.user.uid
    updateUser.authorityId = this.user.authorityId
    updateUser.contact = new Contact()
    updateUser.contact.e164Number = '+82' + this.phoneNumberFirst  + this.phoneNumberSecond + this.phoneNumberThird

    updateUser.attributes = {
      company : this.user.attributes.company
    }
    //this.user.contact = new Contact()
    //this.user.contact.e164Number = '+82' + this.phoneNumberFirst  + this.phoneNumberSecond + this.phoneNumberThird

    if(this.isUserInformation){
      console.log(updateUser)
      updateUser.userId = this.user.userId
      this.userService.updateUser(updateUser).subscribe(result=>{
        console.log(result)
        if(result){
          this.utilService.openPopup("사용자 수정", "사용자가 정상적으로 수정되었습니다.","success")
          this.modalService.dismissAll()
        }
      })
    }else{
      this.userService.updateMyProfile(updateUser).subscribe(result=>{
        console.log(result)
        if(result){
          this.utilService.openPopup("사용자 수정", "사용자가 정상적으로 수정되었습니다.","success")
          this.modalService.dismissAll()
        }
      })

    }

  }

}
