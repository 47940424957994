import { Injectable } from '@angular/core';
import * as crypto from 'crypto';
import { Subject } from 'rxjs';
import * as shajs from 'sha.js';
import { DEPLOY_APPROVED, DEPLOY_ASSIGNED, DEPLOY_REJECTED, DEPLOY_REVIEW, PURCHASE_APPROVED, PURCHASE_ASSIGNED, PURCHASE_REJECTED, VALIDATION_STATUS_ERROR, VALIDATION_STATUS_FAIL, VALIDATION_STATUS_PASS, VALIDATION_STATUS_PENDING, VALIDATION_STATUS_PROCESSING } from 'src/constants';

const Swal = require('sweetalert2')

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  fileRead : string;
  fileReadSubject = new Subject<string>();
  fileRead$ = this.fileReadSubject.asObservable();
  

  loading : boolean = false;
  loadingSubject = new Subject<boolean>()
  loading$ = this.loadingSubject.asObservable();



  swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'ant-btn ant-btn-primary',
      cancelButton: 'ant-btn'
    },
    buttonsStyling: false,
  })

  getDateTimeFormat(date : Date){
    let dateTime : string = ""
    dateTime = date.getFullYear() + "-"
    if(date.getMonth() < 9){
      dateTime += "0"
    }
    dateTime += date.getMonth()+1 + "-"

    if(date.getDate() < 10){
      dateTime += "0"
    }
    dateTime += date.getDate() + " "

    if(date.getHours() < 10){
      dateTime += "0"
    }

    dateTime += date.getHours() + ":"

    if(date.getMinutes() < 10){
      dateTime += "0"
    }

    dateTime += date.getMinutes() + ":"

    if(date.getSeconds() < 10){
      dateTime += "0"
    }

    dateTime += date.getSeconds()

    return dateTime
  }

  getDateTimeFormatYearMonthDate(date : any){
    let currentDate = new Date(date)
    let resultDate : string  = ""

    resultDate = currentDate.getFullYear() + "-"
    if(currentDate.getMonth() < 9){
      resultDate += "0"
    }
    resultDate += currentDate.getMonth()+1 + "-"

    if(currentDate.getDate() < 9){
      resultDate += "0"
    }
    resultDate += currentDate.getDate() + " "

    return resultDate;

  }

  getDateTimeFormatYesterdayYearMonthDate(date : any){
    let currentDate = new Date(date)
    let resultDate : string  = ""

    currentDate.setDate(currentDate.getDate()-1)

    resultDate = currentDate.getFullYear() + "-"
    if(currentDate.getMonth() < 9){
      resultDate += "0"
    }
    resultDate += currentDate.getMonth()+1 + "-"

    if(currentDate.getDate() < 9){
      resultDate += "0"
    }
    resultDate += currentDate.getDate() + " "

    return resultDate;

  }

  getDateTimeFormatTomorrowYearMonthDate(date : any){
    let currentDate = new Date(date)

    currentDate.setDate(currentDate.getDate()+1)

    let resultDate : string  = ""

    resultDate = currentDate.getFullYear() + "-"
    if(currentDate.getMonth() < 9){
      resultDate += "0"
    }
    resultDate += currentDate.getMonth()+1 + "-"

    if(currentDate.getDate() < 9){
      resultDate += "0"
    }
    resultDate += currentDate.getDate() + " "

    return resultDate;

  }

  

  setDateTime(date : any, time : any){
    let dateTime : string
    dateTime = date.year + "-"

    if(date.month < 10){
      dateTime += "0"
    }
    dateTime += date.month + "-"

    if(date.day < 10){
      dateTime += "0"
    }

    dateTime += date.day + " "

    if(!time.hour || !Number(time.hour)){
      time.hour = 0
    }

    if(time.hour < 10){
      dateTime += "0"
    }

    dateTime += time.hour + ":"

    if(!time.minute || !Number(time.minute)){
      time.minute = 0
    }

    if(time.minute < 10){
      dateTime += "0"
    }

    dateTime += time.minute + ":"

    if(!time.second || !Number(time.second)){
      time.second = 0
    }

    if(time.second < 10){
      dateTime += "0"
    }
    dateTime += time.second

    return dateTime

  }

  getBase64(file) {
    return new Promise<string | ArrayBuffer> ( (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result

        if(typeof result == "string"){
          result = result.substr((result.indexOf("base64,")+7))
        } 
        resolve(result);
      }
      reader.onerror = error => reject(error);
    });
  }

  sha256Encryption(text : String){
    return shajs('sha256').update(text).digest('hex')
  }


  setLoading(isLoading:boolean) {
    console.log(isLoading)
    this.loading = isLoading
    this.loadingSubject.next(this.loading)
  }

  openPopup(title : string, content : string, type : string){
    this.swalWithBootstrapButtons.fire({
      icon : type,
      title : title,
      text : content,
      confirmButtonText: '확인',
    })
  }

  getDateTimeYear(date : any){
    let currentDate = new Date(date);
    return currentDate.getFullYear();

  }

  convertBytesToMB(bytes: number): number {
    return parseFloat((bytes / 1024 / 1024).toFixed(2));
  } 
  
  getDeployText( val : string ): string {
    if( val === DEPLOY_ASSIGNED ){
      return "등록 신청";
    }else if( val === DEPLOY_REVIEW ){
      return "심사 중";
    }else if( val === DEPLOY_APPROVED ){
      return "등록 승인";
    }else if( val === DEPLOY_REJECTED ){
      return "등록 반려";
    }
    return '';
  } 
  getPurchaseStatusText( val : string ): string {
    if( val === PURCHASE_ASSIGNED ){
      return "신청";
    }else if( val === PURCHASE_APPROVED ){
      return "승인";
    }else if( val === PURCHASE_REJECTED ){
      return "반려";
    }
    return '';
  }   

  getValidationStatusText( val : string ): string {
    if( val === VALIDATION_STATUS_PASS ){
      return "성공";
    }else if( val === VALIDATION_STATUS_PENDING ){
      return "대기";
    }else if( val === VALIDATION_STATUS_PROCESSING ){
      return "진행중";
    }else if( val === VALIDATION_STATUS_FAIL ){
      return "실패";
    }else if( val === VALIDATION_STATUS_ERROR ){
      return "에러";
    }

    return '';
  } 

}
