import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public show: boolean = true;

  constructor(private utilService : UtilService) {
    setTimeout(() => {
      this.show = false;
    }, 1000);
  }

  loading$ : Subscription

  ngOnInit() {
    console.log("init")
    this.loading$ = this.utilService.loading$.subscribe(result=>{
      console.log(result)
      this.show = result
    })


   }

  ngOnDestroy() {
    console.log("destroy")
    if(this.loading$)this.loading$.unsubscribe()
   }

}
