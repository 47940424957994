<ng-template #dataFormView let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">데이터 {{type == 'add' ? "추가" : type == 'correction' ? "수정" : "확인"}}</h5>
        <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">

            <div class="form-group col-md-12" *ngIf="type != 'add'">
                <div class="form-label">연구자 ID</div>
                <input class="form-control" type="text" required="" autocomplete="off" readonly [(ngModel)]="documentResearchData.meta.patientId">
            </div>

            <div class="form-group col-md-6 " *ngIf="type == 'add'">
                <div class="form-label">병원명</div>
                <input class="form-control" type="text" required="" autocomplete="off" [(ngModel)]="hospitalName">
            </div>

            <div class="form-group col-md-6 " *ngIf="type == 'add'">
                <div class="form-label">고객 ID</div>
                <input class="form-control" type="text" required="" autocomplete="off" [(ngModel)]="residentRegistrationNumber">
            </div>

            <div class="form-group col-md-12" style="margin-bottom: 50px;">
                <div class="form-label">수집 일시</div>
                <form class="form-inline custom-datepicker custom-datepicker-dropdown" style="width: 100%; display: block;">
                    <div class="form-group" style="display: block; width: 100%;">
                      <div class="input-group" style="position: relative;"  >
                        <input 
                        style="position: absolute;"
                        readonly 
                        class="form-control" 
                        [(ngModel)]="date" 
                        ngbDatepicker 
                        [footerTemplate]="footerTemplate" 
                        #f="ngbDatepicker"
                        (ngModelChange)="changeDate($event)"
                        [disabled]="type == 'check'">
                        <div class="form-control" style="position: absolute; width: 100%;height: 40px; border-radius: 5px;" (click)="type == 'check' ? null : f.toggle() " [ngClass]="{'notDateTime': !dateTime}" >{{dateTime ? dateTime : ""}}</div>
                      </div>
                      
                      <ng-template #footerTemplate>
                        <div class="time-picker-box" >
                          <ngb-timepicker [(ngModel)]="time" [seconds]="true" class="time-picker-custom" (ngModelChange)="changeDate($event)"></ngb-timepicker>                        
                        </div>
                      </ng-template>
                    </div>
                </form>
            </div>

            <div class="form-group col-md-6">
                <div class="form-label">대분류</div>
                <select class="form-control" [(ngModel)]="currentMainCategory" (change)="setMainCategory()" [disabled]="type != 'add'" >
                    <option *ngFor="let category of mainCategoryData.subCategories" [disabled]="type != 'add'" [ngValue]="category.categoryCode">{{category.displayName}}</option>
                </select>
            </div>

            <div class="form-group col-md-6">
                <div class="form-label">중분류</div>
                <select id="middleCategoryBox" class="form-control" [(ngModel)]="documentResearchData.meta.categoryCode" (change)="setMiddleCategory()" [disabled]="type != 'add'">
                    <option style="display:none"></option>
                    <option *ngFor="let category of subCategoryData.subCategories" [disabled]="type != 'add'" [ngValue]="category.categoryCode" >{{category.displayName}}</option>
                </select>
            </div>

            <div class="form-group col-md-12" >

                <div class="dataSourceBox" *ngFor="let item of categoryDataSchema.schema">
                    <div class="dataSourceKey">
                        <div class="dataSourceKeyText">
                            {{item.displayName}}
                        </div>
                    </div>
                    <span *ngIf="!item.allowNull" style="color:red; position: absolute;">*</span> 
                    <input *ngIf="item.valueType == 'INTEGER'" class="dataSourceValue" [onlyNumbers] [(placeholder)]="item.valueType" type="text" [(ngModel)]="item.value" [disabled]="type=='check'" (ngModelChange)="changeValue(item)" >
                    <input *ngIf="item.valueType == 'DOUBLE'" class="dataSourceValue" [(placeholder)]="item.valueType" type="number" [(ngModel)]="item.value"[disabled]="type=='check'" (ngModelChange)="changeValue(item)">

                    <select *ngIf="item.valueType == 'BOOLEAN'" class="form-control dataSourceValue" [(ngModel)]="item.value" [disabled]="type=='check'" [ngClass]="{'colorGray': item.value == null}" (ngModelChange)="changeValue(item)">
                        <option [value]="null" disabled style="color: #b9b9b9;">BOOLEAN</option>
                        <option [value]="true" style="color: black;">TRUE</option>
                        <option [value]="false" style="color: black;">FALSE</option>
                    </select>

                    <input *ngIf="item.valueType != 'INTEGER' && item.valueType != 'DOUBLE' && item.valueType != 'BOOLEAN' " class="dataSourceValue" [(placeholder)]="item.valueType" type="text" [(ngModel)]="item.value" [disabled]="type=='check'" (ngModelChange)="changeValue(item)">
                </div>


                <div class="d-flex justify-content-center p-2" style="margin-top:10px;">
                    <nz-pagination 
                      [nzTotal]="categoryDataSchema.count"
                      [(nzPageIndex)]="page"
                      [nzPageSize]="pageSize">
                    </nz-pagination>
                </div>

            </div>

            <div class="form-group col-md-12" *ngIf="(type != 'check' || documentResearchData.attachments.length != 0) && isAttachmentCategory">
                <label >첨부파일</label>
            </div>

            <div class="form-group col-md-12" >
                <div class="attachmentBox" *ngFor="let file of oldFiles">
                    <div *ngIf="type!='check'" class="attachmentDelete" (click)="deleteOldFiles(file)"><i class="icon-trash" style="color: red;"></i></div>
                    <img *ngIf="file.thumbnail" [src]="'data:image/jpeg;base64,' + file.thumbnail" style="width: 150px; height: 100px;">    
                    <div class="attachmentNameBox" *ngIf="!file.thumbnail">
                        {{file.originFileName}}
                    </div>
                </div>

                <div class="attachmentBox" *ngFor="let file of newFiles">
                    <div *ngIf="type!='check'" class="attachmentDelete" (click)="deleteFiles(file)"><i class="icon-trash" style="color: red;"></i></div>
                    <img *ngIf="file.imgURL" [src]="file.imgURL" style="width: 150px; height: 100px;">
                    <div *ngIf="!file.imgURL" class="attachmentNameBox">
                        {{file.file.name}}
                    </div>
                </div>

                <input type="file" #fileInput style="display: none;" (change)="uploadFile($event)">
                <div class="attachmentBox" style="cursor: pointer;" *ngIf="type != 'check' && isAttachmentCategory" (click)="fileInput.click()">
                    <div  class="attachmentNameBox">
                        <i class="fa fa-plus"></i>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-primary" type="button" style="float: right; margin-left: 10px;" data-dismiss="modal" *ngIf="type != 'check'" (click)="modal.dismiss('Cross click')">취소 </button>
        <button class="btn btn-primary" type="button" style="float: right;" data-dismiss="modal" *ngIf="type != 'check'" (click)="dataSave()">저장</button>
        <button class="btn gray" type="button" style="float: right;" data-dismiss="modal" *ngIf="type == 'check'" (click)="modal.dismiss('Cross click')">닫기</button>
    </div>
</ng-template>