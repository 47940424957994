import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser, KeyValue } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ResearchdataService } from 'src/app/shared/services/researchdata.service';
import { Filter } from 'src/app/shared/data-object/Filter';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-data-form-view',
  templateUrl: './data-form-view.component.html',
  styleUrls: ['./data-form-view.component.scss']
})
export class DataFormViewComponent implements OnInit {

  newFiles : any[] = []
  oldFiles : any[] = []

  deleteOldFileList : any[] = []

  @ViewChild("dataFormView", { static: false }) dataFormView: TemplateRef<any>;
  @Output() isData = new EventEmitter<any>();

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private researchdataService : ResearchdataService,
    private metadataService : MetadataService,
    private utilService : UtilService,
  ) { }
  filter : Filter = new Filter();
  currentPage = 1;
  pageSize : number = 30;

  get page(){return this.currentPage}
  set page(page:number){
    this.currentPage = page
    this.filter.offset = ((this.currentPage-1) * this.pageSize)
    this.getCategoryDataSchema()
  }

  mainCategoryData : any = { }
  subCategoryData : any = {}

  data : any
  type : string = ""


  date : any = {
    year : '',
    month : '',
    day : ''
  }

  dateTime : string = ""

  time : any = {
    hour: 0,
    minute : 0,
    second : 0,
  }

  currentMainCategory
  currentSubCategory

  categoryDataSchema : any = {
    count : 0,
    schema : []
  }

  documentResearchData : any = {
    attachments : [],
    data : {}, 
    meta : {}
  }

  hospitalName : string = ""
  residentRegistrationNumber : string = ""

  isAttachmentCategory : boolean = false



  schemaValue = {}



  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  characterNumberOrder = (a, b): number => {
    return (Number(a.key.match(/(\d+)/g)[0]) - Number((b.key.match(/(\d+)/g)[0])))
  }

  ngOnInit(): void {
    this.metadataService.getCategorie(new Filter(), "ROOT").subscribe(result=>{
      this.mainCategoryData = result
    })
  }

  changeDate(event: any){
    this.dateTime = this.utilService.setDateTime(this.date, this.time)
  }

  openModal(data : any, type : string) {
    console.log(data)
    this.documentResearchData = {
      attachments : [],
      data : {}, 
      meta : {}
    }
    this.hospitalName = null
    this.residentRegistrationNumber = null
    this.data = data;
    this.type = type;
    this.subCategoryData = {}
    this.currentMainCategory = ''
    this.currentSubCategory = ''
    this.newFiles = []
    this.dateTime = null
    this.oldFiles = []
    this.deleteOldFileList = []
    this.isAttachmentCategory = false
    this.categoryDataSchema = {
      count : 0,
      schema : []
    }

    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.dataFormView, { 
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    if(type == "check" || type == "correction"){
      this.researchdataService.getDocumentResearchData(data.documentId).subscribe(async result=>{
        if(result){
          this.documentResearchData = result

          let metaCollectTs = new Date(this.documentResearchData.meta.collectTs);
  
          this.date.year = metaCollectTs.getFullYear()
          this.date.month = metaCollectTs.getMonth() + 1
          this.date.day = metaCollectTs.getDate()
  
          this.time.hour = metaCollectTs.getHours()
          this.time.minute = metaCollectTs.getMinutes()
          this.time.second = metaCollectTs.getSeconds()
  
          this.dateTime = this.utilService.setDateTime(this.date, this.time)
  
          await this.setMiddleCategory().then(()=>{
            for(let obj in result.data){
              this.schemaValue[obj] = result.data[obj]
              for(let i = 0; i< this.categoryDataSchema.schema.length; i++){
                if(obj == this.categoryDataSchema.schema[i].subjectCode){
                  this.categoryDataSchema.schema[i]["value"] = result.data[obj]
                  break;
                }
              }
            }

            for(let obj in result.attachments){
              this.oldFiles.push(result.attachments[obj])
            }
          })
          
          this.metadataService.getCategorie(new Filter(),this.documentResearchData.meta.categoryCode).subscribe(categoryResult=>{
            console.log(categoryResult)
            this.currentMainCategory = categoryResult.categoryInfo.parentCode
            this.currentSubCategory = this.documentResearchData.meta.categoryCode
            this.getCategories()
          })
        }else {
          this.utilService.openPopup("데이터", "문서를 불러오지 못했습니다.", "error")
          this.modalService.dismissAll()
        }
      })

    }else if(type == "add"){

    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

   setMainCategory(){
    this.documentResearchData.meta.categoryCode = null
    this.categoryDataSchema = {
      count : 0,
      schema : []
    };

    (document.getElementById("middleCategoryBox") as HTMLSelectElement).selectedIndex = -1
    
    this.getCategories()
  }

  getCategories(){
    this.metadataService.getCategorie(new Filter(), this.currentMainCategory).subscribe( result=>{
      console.log(result)
      this.subCategoryData = result
    })
  }

   setMiddleCategory(){

    this.metadataService.getCategorie(new Filter(), this.documentResearchData.meta.categoryCode).subscribe(result=>{
      this.isAttachmentCategory = result.categoryInfo.isAttached
    })

    return new Promise(((resolve,reject) =>{
      this.getCategoryDataSchema().then(()=>{
        resolve(true)
      });
    })) 
  }

  getCategoryDataSchema(){
    return new Promise(((resolve,reject) =>{
      this.metadataService.getCategoryDataSchema(this.documentResearchData.meta.categoryCode, null, this.filter).subscribe(result=>{
        console.log(result)
        this.categoryDataSchema = result
        for(let i = 0; i < this.categoryDataSchema.schema.length; i++){
          if(this.schemaValue[this.categoryDataSchema.schema[i].subjectCode]){
            this.categoryDataSchema.schema[i].value = this.schemaValue[this.categoryDataSchema.schema[i].subjectCode]
          }
        }
        if(result){
          resolve(true)
        }else {
          this.categoryDataSchema = {
            count : 0,
            schema : []
          }
          reject(false)
        }
      })
    })) 
  }

  async dataSave(){
    console.log(this.documentResearchData.meta)
    if(this.type == 'add'){
      if(this.hospitalName == ""){
        this.utilService.openPopup("데이터 입력","이름을 입력해주세요.","info")
        return 
      }
      if(this.residentRegistrationNumber == ""){
        this.utilService.openPopup("데이터 입력","주민등록번호를 입력해주세요.","info")
        return 
      }
    }else if(this.type == 'correction'){

    }

    if(!this.documentResearchData.meta.categoryCode){
      this.utilService.openPopup("데이터 입력","카테고리를 입력해주세요.","info")
      return
    }

    for(let i = 0; i < this.categoryDataSchema.count/this.pageSize; i++){
      let f = new Filter()
      f.offset = (i * this.pageSize)
      new Promise(((resolve,reject) =>{
        this.metadataService.getCategoryDataSchema(this.documentResearchData.meta.categoryCode, null, this.filter).subscribe(result=>{
          console.log(result)
          for(let i = 0; i < this.categoryDataSchema.schema.length; i++){
            if(!result.schema[i].allowNull && (!this.schemaValue[result.schema[i].subjectCode] || this.schemaValue[result.schema[i].subjectCode].value)){
              this.utilService.openPopup("데이터 추가","필수 응답은 모두 기입해주세요.", "info")
              return
            }
          }
        })
      }))
    }

    let collectTs = null
    if(this.dateTime != null){
      collectTs = new Date(this.dateTime).toISOString()
    }

    if(this.type == 'add'){
      let patientId = this.utilService.sha256Encryption(this.hospitalName+this.residentRegistrationNumber)

      this.researchdataService.addResearchData(this.documentResearchData.meta.categoryCode,patientId,collectTs, this.schemaValue ).subscribe(async result=>{

        if(result){
          for(let i = 0; i < this.newFiles.length; i++){
            let base64 = await this.utilService.getBase64(this.newFiles[i].file)
            await this.researchdataService.uploadAttachFile(result.documentId, this.newFiles[i].file.name, base64).subscribe(result2=>{ 
              if(!result2){
                this.utilService.openPopup("데이터 추가", "첨부파일을 추가하는도중 에러가 발생하였습니다.", "error")
              }
             })
          }
          this.utilService.openPopup("데이터 추가", "데이터가 추가되었습니다.","success")
          this.isData.emit(true)
          this.modalService.dismissAll()
        }
      })
    }else if(this.type == 'correction'){
      this.researchdataService.updatedDocumentResearchData(this.documentResearchData.meta, collectTs, this.schemaValue).subscribe(async result=>{
        console.log(result)
        if(result){
          this.utilService.openPopup("데이터 수정", "데이터가 수정되었습니다.","success")
          for(let i = 0; i < this.newFiles.length; i++){
            let base64 = await this.utilService.getBase64(this.newFiles[i].file)
            await this.researchdataService.uploadAttachFile(this.data.documentId, this.newFiles[i].file.name, base64).subscribe(result2=>{ 
              if(!result2){
                this.utilService.openPopup("데이터 수정", "첨부파일을 추가하는도중 에러가 발생하였습니다.","error")
              }
            })
          }

          let uuidArray : string[] = []

          for(let i = 0; i < this.deleteOldFileList.length; i++){
            uuidArray.push(this.deleteOldFileList[i].uuid+"")
          }

          if(uuidArray.length > 0){
            await this.researchdataService.deleteAttachFile(this.data.documentId,uuidArray).subscribe(result2=>{
              console.log(result2)
            })
          }
          this.isData.emit(true)
          this.modalService.dismissAll()
        }else {
          this.utilService.openPopup("데이터 수정 실패", "판매 심사중인 데이터 혹은 판매중인 데이터는 수정 할 수 없습니다.","info")
        }

      })
    }
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  encodeImageFileAsURL(element) {
    var file = element.files[0];
    var reader = new FileReader();
    reader.onloadend = function() {
      console.log('RESULT', reader.result)
    }
    reader.readAsDataURL(file);
  }
  

  onSelect(event) {
    console.log(event)
  }

  async uploadFile(event: Event) {
    console.log(event)
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if(files.length > 0){
      if(files[0].type.indexOf('image') > -1){
        var reader = new FileReader();
        reader.readAsDataURL(files[0]); 
        reader.onload = (_event) => { 
          this.newFiles.push({
            file : files[0],
            imgURL : reader.result
          })
        }
      }else{
        this.newFiles.push({
          file : files[0],
          imgURL : ''
        })
      }
    }
    console.log(files);

  }

  deleteFiles(file){
    if(this.newFiles.indexOf(file) > -1){
      this.newFiles.splice(this.newFiles.indexOf(file),1)
    }
  }

  deleteOldFiles(file){
    if(this.oldFiles.indexOf(file) > -1){
      this.oldFiles.splice(this.oldFiles.indexOf(file),1)
    }
    this.deleteOldFileList.push(file)
  }

  changeValue(item : any){
    console.log(item)
    this.schemaValue[item.subjectCode] = item.value
  }
}
