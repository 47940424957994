<div class="media profile-media" (click)="isProfileOnOff = !isProfileOnOff">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <div class="media-body"><span>{{user.surname}}{{user.username}}</span>
    <p class="mb-0 font-roboto"> {{user.authority.typeName}} <i class="middle fa fa-angle-down"></i></p>
  </div>

</div>
<ul class="profile-dropdown onhover-show-div" style="position: absolute; background: white;">
  <li (click)="myAccount()"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>

<app-user-account #userAccount (passwordChangeOpen)="passwordChangeOpen($event)"></app-user-account>
<app-chagne-password #chagnePassword></app-chagne-password>
