import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { MegaMenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { BookmarkComponent } from './components/header/elements/bookmark/bookmark.component';
import { CartComponent } from './components/header/elements/cart/cart.component';
import { MessageBoxComponent } from './components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
// Services
import { ChatService } from './services/chat.service';
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { PurchasesService } from './services/purchases.service'
import { CorellationsService } from './services/corellations.service'
import { FavoritesService } from './services/favorites.service'
import { MetadataService } from './services/metadata.service'
import { ResearchdataService } from './services/researchdata.service'
import { StatisticsService } from './services/statistics.service'
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { DecimalPipe } from '@angular/common';
import { UserLevelTextPipe } from './pipes/user-level-text.pipe';
import { UserAccountComponent } from './modal/user-account/user-account.component';
import { NoopInterceptor } from './intercept/noop-interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChagnePasswordComponent } from './modal/chagne-password/chagne-password.component';
import { DataFormViewComponent } from './modal/data-form-view/data-form-view.component';
import { DataSearchComponent } from './modal/data-search/data-search.component'
import { UtilService } from './services/util.service';
import { SafePipe } from './pipes/safe.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe'
import { AddDetailCategoryComponent } from '../components/dataSearch/modal/add-detail-category/add-detail-category.component';
import { BuyDataComponent } from '../components/dataSearch/modal/buy-data/buy-data.component';
import { DateFormatYearMonthDatePipe } from './pipes/date-format-year-month-date.pipe';
import { ToLocaleStringPipe } from './pipes/to-locale-string.pipe';
import { ByteToMemoryPipe } from './pipes/byte-to-memory.pipe';
import { WriteReviewMetadataComponent } from './modal/write-review-metadata/write-review-metadata.component';
import { NgZorroAntdModule } from '../ng-zorro-antd.module';
import { DataTypePipe } from './pipes/data-type.pipe';
import { ConsistencyCheckStatusPipe } from './pipes/consistency-check-status.pipe';
import { StringNaNPipe } from './pipes/string-na-n.pipe';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    NgbdSortableHeader,
    UserLevelTextPipe,
    UserAccountComponent,
    ChagnePasswordComponent,
    DataFormViewComponent,
    DataSearchComponent,
    SafePipe,
    DateFormatPipe,
    AddDetailCategoryComponent,
    BuyDataComponent,
    DateFormatYearMonthDatePipe,
    ToLocaleStringPipe,
    ByteToMemoryPipe,
    WriteReviewMetadataComponent,
    DataTypePipe,
    ConsistencyCheckStatusPipe,
    StringNaNPipe,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragulaModule.forRoot(),
    TranslateModule.forRoot(),
    NgZorroAntdModule
  ],
  providers: [
    NavService,
    ChatService,
    LayoutService,
    DecimalPipe,
    PurchasesService, 
    CorellationsService,
    FavoritesService,
    MetadataService,
    ResearchdataService,
    StatisticsService,
    UtilService,
    { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true }
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DataSearchComponent,
    AddDetailCategoryComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    SafePipe,
    DateFormatPipe,
    UserAccountComponent,
    DataFormViewComponent,
    BuyDataComponent,
    DateFormatYearMonthDatePipe,
    ToLocaleStringPipe,
    ByteToMemoryPipe,
    WriteReviewMetadataComponent,
    DataTypePipe,
    ConsistencyCheckStatusPipe,
    StringNaNPipe
  ],
})
export class SharedModule { }
