import { Attributes } from "./Attributes"
import { Authority } from "./Authority"
import { Contact } from "./Contact"

export class User{
    userId : string 
    email : string
    username : string
    surname : string 
    uid : string
    authorityId : string
    contact : Contact
    issuer : string
    createdAt : string
    status : string 
    attributes : any = {
        company : ""
    }
    latestAccess : string 
    authority : Authority = new Authority()
}