export const ADD_CORPORATE_DATA = "ADD_CORPORATE_DATA";
export const ADD_PUBLIC_DATA = "ADD_PUBLIC_DATA";

export const DEPLOY_ASSIGNED = "ASSIGNED";
export const DEPLOY_REVIEW = "REVIEW";
export const DEPLOY_APPROVED = "APPROVED";
export const DEPLOY_REJECTED = "REJECTED";

export const PURCHASE_LIST_TYPE = "USER";
export const PURCHASE_SALES_LIST_TYPE = "PROVIDER";

export const CORRELATIONS_SUCCESS = "SUCCESS";

export const PURCHASE_ASSIGNED = "ASSIGNED" //신청
export const PURCHASE_APPROVED = "APPROVED" //승인
export const PURCHASE_REJECTED = "REJECTED" //반려

export const USER_ADMIN = "admin";
export const USER_ROOT = "root";
export const USER_USER = "user";
export const USER_PROVIDER = "provider";

export const ANALYSIS_PEARSON = "PEARSON";
export const ANALYSIS_SPEARMAN = "SPEARMAN";
export const ANALYSIS_KENDALL = "KENDALL";

export const PRICE = "PRICE";
export const CREATED_AT = "CREATED_AT";

export const VALIDATION_STATUS_PASS = "PASS"; //성공
export const VALIDATION_STATUS_PENDING = "PENDING"; //대기
export const VALIDATION_STATUS_PROCESSING = "PROCESSING"; //진행중
export const VALIDATION_STATUS_FAIL = "FAIL"; //실패
export const VALIDATION_STATUS_ERROR = "ERROR"; //에러
