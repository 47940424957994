import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userLevelText'
})
export class UserLevelTextPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {


    if(value == 1){
      return "관리자";
    }else if(value == 2){
      return "제공자";
    }else if(value == 3){
      return "사용자";
    }else{
      return value;
    }

  }

}
