import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'consistencyCheckStatus'
})
export class ConsistencyCheckStatusPipe implements PipeTransform {

  transform(value: unknown): unknown {
    let result : string = ""

    if(value == "PENDING"){
      result="정합성 검사 대기"
    }else if(value == "PROCESSING"){
      result = "정합성 검사 진행 중"
    }else if(value == "PASS"){
      result = "정합성 검사 통과"
    }else if(value == "FAIL"){
      result = "정합성 검사 실패"
    }else if(value == "ERROR"){
      result = "정합성 검사 중 에러"
    }

    return result;
  }

}
