import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-chagne-password',
  templateUrl: './chagne-password.component.html',
  styleUrls: ['./chagne-password.component.scss']
})
export class ChagnePasswordComponent implements OnInit {

  @ViewChild("chagnePassword") chagnePassword: ChagnePasswordComponent;  
  @ViewChild("closebutton", { static: false }) closebutton: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private userService : UserService,
    private utilService : UtilService
  ) { }

  previousPasswordSohw : boolean = false
  newPasswordSohw : boolean = false
  newPasswrodRepeatedShow : boolean = false

  previousPassword : string = ""
  newPassword : string = ""
  newPasswrodRepeated : string = ""
  
  modalReference : any

  ngOnInit(): void {
  }

  openModal() {
    this.previousPasswordSohw = false
    this.newPasswordSohw = false;
    this.newPasswrodRepeatedShow = false;

    this.previousPassword = ""
    this.newPassword = ""
    this.newPasswrodRepeated = ""
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalReference = this.modalService.open(this.chagnePassword, { 
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      })
    }
  }

  updatePassword(){
    console.log("updatePassword")

    if(this.previousPassword == ""){
      this.utilService.openPopup("비밀번호 변경", "기존 비밀번호를 입력해주세요.", "info")
      return;
    }

    if(this.newPassword == ""){
      this.utilService.openPopup("비밀번호 변경", "새 비밀번호를 입력해주세요.", "info")
      return
    }

    if(this.newPasswrodRepeated == ""){
      this.utilService.openPopup("비밀번호 변경", "새 비밀번호 확인을 입력해주세요.", "info")
      return
    }

    if(this.newPassword != this.newPasswrodRepeated){
      this.utilService.openPopup("비밀번호 변경", "새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.", "error")
      return
    }

    if(this.newPassword.length < 8){
      this.utilService.openPopup("비밀번호 변경", "새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.", "error")
      return
    }
    
    let parameter = {
      old: this.previousPassword,
      new: this.newPassword
    }

    this.userService.chagneUsersPassword(parameter, "").subscribe(
      res=>{
        console.log(res)
        if(res){
          this.utilService.openPopup("비밀번호 변경", "비밀번호가 변경되었습니다.", "success")
          this.modalReference.dismiss('Cross click')
        }
      }, error=>{
        console.log(error)
        if(error.status == 400){
          this.utilService.openPopup("비밀번호 변경", "비밀번호 변경중 오류가 발생했습니다.", "error")
        }else if(error.status == 401){
          this.utilService.openPopup("비밀번호 변경", "비밀번호 변경중 오류가 발생했습니다. 기존 비밀번호가 일치하지 않습니다.", "error")
        }
      })

  }
}
