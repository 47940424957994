import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../services/util.service'


@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {


  constructor(
    private utilService: UtilService
  ) { }

  transform(isoDate : string): unknown {
    if( !isoDate || isoDate === undefined || isoDate === null || isoDate === "" ){
      return "";
    }
    let date = new Date(isoDate);
    let result : string = this.utilService.getDateTimeFormat(date)
    
    return result;
  }

}
