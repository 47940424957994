<div class="container-fluid p-0">
  <div class="row" style="width:100%; height:100%; margin: 0; padding: 0;">
    <div class="col-12">
      <div class="login-image">
        <img  src="assets/images/login/login-image.jpg" alt="looginpage">
      </div>
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img  class="img-fluid" src="assets/images/logo/logo2.png" alt="looginpage">
              <!--<img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">-->
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form" [formGroup]="loginForm">
              <h4>웰케어 빅데이터 플랫폼</h4>
              <p>웰케어 빅데이터 플랫폼은 웰케어 산업특화 데이터를 수집하여 저장하고 새로운 웰케어 비즈니스를 위한 융합 데이터를 제공하기 위한 지원 시스템입니다.</p>

              <p>웰케어 빅데이터 플랫폼은 데이터를 활용하기위한 일반 사용자와 데이터를 제공하기위한 제공자 계정을 제공합니다. 제공자 계정은 일반 사용자 계정에서 제공되는 기능을 모두 포함합니다. 회원가입 시 원하시는 계정을 생성하세요.</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="E-mail" formControlName="email">
                  <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                      class="text text-danger mt-1 alertText">
                      Email is required
                  </div>
                  <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                      class="text text-danger mt-1 alertText">
                      Invalid Email
                  </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input [(ngModel)]="pw" class="form-control"
                  [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="Password" 
                  (keydown.enter)="login()"
                >
                <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> -->
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                      class="text text-danger mt-1 alertText">
                      Password is required
                </div>
              </div>
            
              <div class="error-message-box">
                <div class="error-message" *ngIf="errorMessage"> {{errorMessage}} </div>
              </div>

              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" [checked]="rememberPasswordCheck"
                    (change)="rememberPasswordCheck = $event.target.checked"
                  >
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div> -->
                <a class="link" style="cursor: pointer;" (click)="forgetPassword()">비밀번호 찾기</a>

                <button 
                nz-button 
                nzType="primary" 
                nzShape="round" 
                (click)="login()" 
                style="width:100%"
                ><span>{{ authService.showLoader ? '' : 'Login' }}</span></button> 
              </div>
              <p class="mt-4 mb-0">
                <a class="ms-2" style="cursor: pointer;" (click)="signUp()">회원가입</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-password-forgot #passwordForgot></app-password-forgot>
<app-reset-password #resetPassword></app-reset-password>