import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringNaN'
})
export class StringNaNPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if( value === "NaN" ){
      value = "";
    }
    return value;
  }

}
