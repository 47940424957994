<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">

        <!--<div>
          <i class="icofont icofont-arrow-left"></i>
        </div>
        -->

        <div style="display: flex; align-items: center; ">
          <img src="../../../../assets/images/setup.png" style="width:22px; height: 22px; margin-right: 10px; margin-top: 1px;">
          <h3 style="font-weight: bold; margin-top: 4px;">{{title}}</h3>
        </div>

      </div>
      <div class="col-6">
      </div>
    </div>
  </div>
</div>