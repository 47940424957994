import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingServiceService {
  private hasShown401Error = false;
  constructor(private router: Router) { }
    
  private log(message: string) {
    console.log(message)  
  }

  handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      
      // HTTP 에러 상태를 체크합니다.
      if (error instanceof HttpErrorResponse) {
        if( error.status === 500 ){
          alert(error.headers.get("Grpc-Message"));
        }else if (error.status === 401 && !this.hasShown401Error) {
          // 401 에러일 경우 로그인 페이지로 리다이렉트합니다.
          alert("장시간 동작이 없어 자동으로 로그아웃 되었습니다.");
          this.router.navigate(['/auth/login']);
          this.hasShown401Error = true;
          setTimeout(() => this.hasShown401Error = false, 1000); // 예: 5초 후 리셋
        }
      }
  
      return of(result as T);
      };
  }
}
