import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../data-object/Filter'
import { Router } from '@angular/router';
import { ErrorHandlingServiceService } from './error-handling-service.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ResearchdataService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlingService : ErrorHandlingServiceService,
  ) { }

  //private Url = environment.httpText + environment.server +':'+environment.port+environment.containerName+'/users';
    private Url = environment.httpText + environment.apiServer + environment.apiText + 'researchdata';

    getResearchDataList(filter : Filter,filters : any[]){ // 연구데이터 메타데이터 목록 조회
      var url = `${this.Url}/list`

      let parameter = {
        asc : filter.asc,
        desc : filter.desc,
        limit : filter.limit,
        offset : filter.offset,
        largeCode : filter.largeCode,
        categoryCode : filter.categoryCode,
        filters : filters,
        patientId : filter.patientId,
        isAttached : filter.isAttached,
        begin : filter.begin,
        end : filter.end
      }

      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /researchdata`) )
      );
    }

    

    addResearchData(categoryCode, patientId, collectTs, data){ // 연구데이터 추가
      var url = `${this.Url}`
      let parameter = {
        categoryCode : categoryCode,
        patientId : patientId,
        collectTs : collectTs,
        data : data
      }
  
      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /researchdata`) )
      );
    }

    getDownloadResearchData(categoryCode : string){ // 연구데이터 다운로드 보유한 데이터만 다운로드 가능
      var url = `${this.Url}/download/${categoryCode}`

      return this.http.get<any>( url).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /researchdata/download/{categoryCode}`) )
      );
    }

    getSampleData(categoryCode : string){ // 카테고리에 해당하는 샘플 데이터 조회
      var url = `${this.Url}/sample/${categoryCode}`

      return this.http.get<any>( url).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /researchdata/sample/{categoryCode}`) )
      );
    }

    uploadResearchData(patientId , collectTs, originFileName, contents ){ // 연구데이터 업로드 csv 파일 업로드
      var url = `${this.Url}/upload`
      let parameter = {
        meta: {
          patientId: patientId,
          collectTs: collectTs,
          originFileName: originFileName
        },
        contents: contents
      }
  
      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /researchdata/upload`) )
      );
    }

    getDocumentResearchData(documentId : string){ // 연구데이터 조회
      var url = `${this.Url}/${documentId}`
      
      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /researchdata/{documentId}`) )
      );
    }

    deleteDocumentResearchData(documentId : string){ // 연구데이터 삭제 판매된 데이터인 경우 삭제 불가
      var url = `${this.Url}/${documentId}`
  
      return this.http.delete<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`DELETE /researchdata/{documentId}`) )
      );
    }

    updatedDocumentResearchData(meta : any, collectTs : string, formatLoad : any){ // 연구데이터 수정 판매된 데이터인 경우 수정 불가
      var url = `${this.Url}/${meta.documentId}`
      let parameter = {
        collectTs : collectTs,
        data: formatLoad
      }

      return this.http.put<any>( url, JSON.stringify(parameter), httpOptions).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`PUT /researchdata/{documentId}`) )
      );
    }

    getAttachFile(documentId : string){ // 연구데이터의 첨부파일 목록 조회
      var url = `${this.Url}/${documentId}/attachments`

      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /researchdata/{documentId}/attachments`) )
      );
    }

    deleteAttachFile(documentId : string, uuid:string[]){ // 연구데이터의 첨부파일 삭제 판매된 데이터인 경우 삭제 불가
      var url = `${this.Url}/${documentId}/attachments`
      for(let i = 0; i < uuid.length; i++){

        if(i == 0){
          url += "?"
        }else {
          url += "&"
        }

        url += "uuid="+uuid[i]
      }

      return this.http.delete<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`DELETE /researchdata/{documentId}/attachments`) )
      );

    }

    uploadAttachFile(documentId, originFileName, contents){ // 연구데이터의 첨부파일 업로드 판매된 데이터인 경우 추가 업로드 불가
      var url = `${this.Url}/${documentId}/attachments`

      let parameter = {
        originFileName: originFileName,
        contents: contents
      }

      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`POST /researchdata/{documentId}/attachments`) )
      );
    }

    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      
      // HTTP 에러 상태를 체크합니다.
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // 401 에러일 경우 로그인 페이지로 리다이렉트합니다.
          alert("장시간 동작이 없어 자동으로 로그아웃 되었습니다.");
          this.router.navigate(['/auth/login']);
        }
      }
      
      return of(result as T);
      };
    }

}
