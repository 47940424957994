export class Filter{
    asc : string
    desc : string

    ascAry : string[] = [];
    descAry : string[] = [];

    limit : number
    offset : number 
    largeCode : string
    categoryCode : string
    patientId : string
    isAttached : boolean
    begin : string
    end : string

    name : string
    largeCategoryName : string
    mediumCategoryName : string
    providerName : string

    code : string

    subjectName : string = ''
    subjectCode : string

    email : string
    authority : string
    attributeName : string
    attributeValue : string
    status : string[] = [];

    buyerName : string

    providerId : string

    subjectIdA : string
    subjectIdB : string

    userId : string
    userName : string 

    largeCategoryCode : string
    mediumCategoryCode : string

    searchField : string;
    searchKeyword : string;
    field : string;
    deploy : string;

    listType : string;
    createdUserId : string;

    search:string

    companydataField:string[]
    companydataDeploy:string[]

    favorite:boolean
    purchase:boolean

    displayName:string
    
    beginTs : string
    endTs : string
}