import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../services/util.service';

@Pipe({
  name: 'dateFormatYearMonthDate'
})
export class DateFormatYearMonthDatePipe implements PipeTransform {

  constructor(
    private utilService: UtilService
  ) { }

  transform(isoDate : string): unknown {

    let date = new Date(isoDate);
    let result : string = this.utilService.getDateTimeFormatYearMonthDate(date)
    

    return result;
  }

}
