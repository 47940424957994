import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { execFile } from 'child_process';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(
    public authService: AuthService,
    public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let user = null
    if(localStorage && localStorage.getItem('user') != 'undefined'){
      user = JSON.parse(localStorage.getItem('user'));
    }

    if (!user || user === null) {
      this.router.navigate(['/auth/login']);
      return true
    } else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/login']);
        return true
      }

      if(user.authorityId == "root" || user.authorityId == "admin"){

        if(state.url.indexOf('/dashboard/manager') < 0 && 
        state.url.indexOf('/dataSearch/personal_data') < 0 &&
        state.url.indexOf('/dataSearch/corporate_data') < 0 &&
        state.url.indexOf('/dataSearch/public_data') < 0 &&
        state.url.indexOf('/correlationAnalysis/personal_data') < 0 &&
        state.url.indexOf('/correlationAnalysis/corporate_data') < 0 &&
        state.url.indexOf('/correlationAnalysis/public_data') < 0 &&
        state.url.indexOf('/transaction-management/purchase') < 0 &&
        state.url.indexOf('/transaction-management/sales') < 0 &&
        state.url.indexOf('/dataManagement/data_management_corporate') < 0 &&
        state.url.indexOf('/dataManagement/data_management_corporate') < 0 &&
        state.url.indexOf('/dataManagement/data_management_public') < 0 &&
        state.url.indexOf('/monitoring/cluster') < 0 &&
        state.url.indexOf('/monitoring/data') < 0 &&
        state.url.indexOf('/users') < 0 &&
        state.url.indexOf('/logTracking') < 0 
        ){
          this.router.navigate(['/dashboard/manager']);
          return true
        }

      }else if(user.authorityId == "user"){

        if(state.url.indexOf('/dashboard/guest') < 0 && 
        state.url.indexOf('/dataSearch/personal_data') < 0 && 
        state.url.indexOf('/dataSearch/corporate_data') < 0 &&
        state.url.indexOf('/dataSearch/public_data') < 0 &&
        state.url.indexOf('/correlationAnalysis/personal_data') < 0 &&
        state.url.indexOf('/correlationAnalysis/corporate_data') < 0 &&
        state.url.indexOf('/correlationAnalysis/public_data') < 0 &&
        state.url.indexOf('/transaction-management/purchase') < 0
        ){
          this.router.navigate(['/dashboard/guest']);
          return true
        }

      }else if(user.authorityId == "provider"){

        if(state.url.indexOf('/dashboard/producer') < 0 && 
        state.url.indexOf('/dataSearch/personal_data') < 0 && 
        state.url.indexOf('/dataSearch/corporate_data') < 0 &&
        state.url.indexOf('/dataSearch/public_data') < 0 && 
        state.url.indexOf('/correlationAnalysis/personal_data') < 0 && 
        state.url.indexOf('/correlationAnalysis/corporate_data') < 0 && 
        state.url.indexOf('/correlationAnalysis/public_data') < 0 && 
        state.url.indexOf('/transaction-management/purchase') < 0 &&
        state.url.indexOf('/transaction-management/sales') < 0 &&
        state.url.indexOf('/dataManagement/data') < 0 

        ){
          this.router.navigate(['/dashboard/producer']);
          return true
        }
      }
    }


    return true
  }
  
}
