import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild("resetPassword") resetPassword: ResetPasswordComponent;
  
  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private userService : UserService,
    private utilService : UtilService
  ) { }

  newPasswordSohw : boolean = false
  newPasswrodRepeatedShow : boolean = false

  newPassword : string = ""
  newPasswrodRepeated : string = ""
  
  token : string

  ngOnInit(): void {
  }

  openModal(token : string) {
    this.token = token
    this.newPassword = ""
    this.newPasswrodRepeated = ""
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.resetPassword, { 
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  updatePassword(){
    console.log("updatePassword")

    if(this.newPassword == ""){
      this.utilService.openPopup("비밀번호 변경","새 비밀번호를 입력해주세요.", "info")
      return
    }

    if(this.newPasswrodRepeated == ""){
      this.utilService.openPopup("비밀번호 변경", "새 비밀번호 확인을 입력해주세요.", "info")
      return
    }

    if(this.newPassword != this.newPasswrodRepeated){
      this.utilService.openPopup("비밀번호 변경", "새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.", "info")
      return
    }

    let parameter = {
      new: this.newPassword
    }
    this.userService.chagneUsersPassword(parameter, this.token).subscribe(
      res=>{
      console.log(res)
      if(res){
        this.modalService.dismissAll()
      }
    }, error=>{
      console.log(error)
      if(error.status == 400){
        this.utilService.openPopup("비밀번호 변경", "비밀번호 변경중 오류가 발생했습니다. 새 비밀번호는 8자리 이상 입력하여야 합니다. \r\n 에러 내용 - " + error.headers.get('grpc-message'), "error")
      }else if(error.status == 401){
        this.utilService.openPopup("비밀번호 변경", "비밀번호 변경중 오류가 발생했습니다. 기존 비밀번호가 일치하지 않습니다. \r\n 에러 내용 - " + error.headers.get('grpc-message'), "error")
      }
    })

  }
}
