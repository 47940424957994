<div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="row" style="margin: 0; padding: 0;">
        <div class="col-12" style="display: flex;   background: url('../../../../assets/images/login/login_bg.jpg');">     
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img  class="img-fluid" src="assets/images/logo/logo2.png" alt="looginpage">
                </a>
              </div>
              <div class="login-main"> 
                <form class="theme-form">
                  <h4>회원가입 {{isSignUp ? "신청이 완료되었습니다." :""}}</h4>
                  <div class="form-group">
                    <label class="col-form-label" for="eMail">E-mail <span> * </span></label>
                    <div class="row">
                      <div class="col-md-12">
                        <input class="form-control mb-1" type="text" id="eMail" [(ngModel)]="user.email" [disabled]="isSignUp">
                        <div *ngIf="checkEmail" class="invalid-feedback">E-mail 입력을 확인해 주세요.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="col-form-label" for="surname">성 <span> * </span></label>
                        <input class="form-control mb-1" type="text" id="surname" [(ngModel)]="user.surname" [disabled]="isSignUp">
                        <div *ngIf="checkSurName" class="invalid-feedback">성을 입력해주세요.</div>
                      </div>

                      <div class="col-md-8">
                        <label class="col-form-label" for="username">이름 <span> * </span></label>
                        <input class="form-control mb-1" type="text" id="username" [(ngModel)]="user.username" [disabled]="isSignUp">
                        <div *ngIf="checkName" class="invalid-feedback">이름을 입력해주세요.</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label">휴대전화 <span> * </span></label>
                    <div class="row">
                      <div class="col-md-4">
                        <select class="form-control" [(ngModel)]="phoneNumberFirst" style="background-color: #f3f3ff;" [disabled]="isSignUp">
                          <option [value]="'010'">010</option>
                          <option [value]="'011'">011</option>
                          <option [value]="'016'">016</option>
                          <option [value]="'017'">017</option>
                          <option [value]="'018'">018</option>
                          <option [value]="'019'">019</option>
                      </select>
                      </div>

                      <div class="col-md-4">
                        <input class="form-control mb-1" type="text" onlyNumbers [(ngModel)]="phoneNumberSecond" [maxlength]="4" [disabled]="isSignUp">
                      </div>
                      <div class="col-md-4">
                        <input class="form-control mb-1" type="text" onlyNumbers [(ngModel)]="phoneNumberThird" [maxlength]="4" [disabled]="isSignUp">
                      </div>
                    </div>
                    <div *ngIf="checkPhoneNumber" class="invalid-feedback">휴대전화를 입력해주세요.</div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label" for="company">소속 </label>
                    <div class="row">
                      <div class="col-md-12">
                        <input class="form-control mb-1" type="text" id="company" [(ngModel)]="user.attributes.company" [disabled]="isSignUp">
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="col-form-label" for="eMail">역할 <span> * </span> </label>
                    <div class="row" style="
                          display: flex;
                          justify-content: left;
                          align-items: center;
                    ">
                      <div class="radio radio-primary col-md-3">
                        <input id="user" type="radio" name="authority" value="user" checked [(ngModel)]="user.authorityId" [disabled]="isSignUp">
                        <label for="user">사용자</label>
                      </div>
                      <div class="radio radio-primary col-md-3">
                          <input id="provider" type="radio" name="authority" value="provider" [(ngModel)]="user.authorityId" [disabled]="isSignUp">
                          <label for="provider">제공자</label>
                      </div>
                      <div class="radio radio-primary col-md-3">
                        <input id="reviewer" type="radio" name="authority" value="reviewer" [(ngModel)]="user.authorityId" [disabled]="isSignUp">
                        <label for="reviewer">평가위원</label>
                    </div>
                    </div>
                    <div *ngIf="checkAuthority" class="invalid-feedback">역할을 입력해주세요.</div>
                  </div>

                  <div class="form-group mb-0">
                    <button *ngIf="!isSignUp" class="btn btn-primary d-block w-100" type="submit" (click)="application()">회원가입</button>
                    <button *ngIf="isSignUp" class="btn btn-primary d-block w-100" type="submit" (click)="moveLogin()">로그인 화면</button>
                  </div>
                  <p *ngIf="!isSignUp" class="mt-4 mb-0">이미 계정이 있습니까?<a class="ms-2" [routerLink]="'/auth/login'">로그인 화면</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>