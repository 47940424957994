import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpContext, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { catchError, finalize, map, Observable, of, Subject, tap } from 'rxjs';
import { UserService } from '../user.service';
import { environment } from 'src/environments/environment';
import { ErrorHandlingServiceService } from '../error-handling-service.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService{
  public showLoader: boolean = false;
  constructor(
    public router: Router,
    private http: HttpClient,
    private userService : UserService,
    private errorHandlingService : ErrorHandlingServiceService,
  ) { }


  private Url = environment.httpText + environment.apiServer ;
  //private Url = 'https://welcare.suredatalab.kr';

  loginErrorMessageSubject = new Subject<string>();
  loginErrorMessage$ = this.loginErrorMessageSubject.asObservable();

  SignIn(url : string, email: string, password: string) : Observable<any> {
    let parameter = {
      login : email,
      password : password
    }

    return this.http.post<any>( url, Object.entries(parameter).map(e => e.join('=')).join('&'), { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }), responseType : 'json', observe: "response" })
  }

  // Sign out
  SignOut() {
    /* localStorage.clear(); */
    localStorage.removeItem('user');
    this.showLoader = false;
    this.router.navigate(['/auth/login']);
  }

  getLoginUrl(){
    let url = `${this.Url}login?autoRedirect=no`;
    return this.http.get( url, {responseType : 'text'} ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`GET /Login URL`) )
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.apiErrorResult(error)
      return of(result as T);
    };
  }

  apiErrorResult(error : any){
    if(error.url.indexOf("https://wellcare.suredatalab.kr/dex/auth/default/login") > -1){
      this.loginErrorMessageSubject.next("ID와 Password를 확인해 주세요.")
    }
  }

}