import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toLocaleString'
})
export class ToLocaleStringPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');;
  }

}
