import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataType'
})
export class DataTypePipe implements PipeTransform {

  transform(value: string): unknown {
    let result : string = ""

    if(value == "INTEGER"){
      result="정수"
    }else if(value == "REAL"){
      result = "실수"
    }else if(value == "STRING"){
      result = "문자열"
    }else if(value == "DATETIME"){
      result = "날짜, 시각"
    }

    return result;
  }

}
