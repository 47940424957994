// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiServer : "wellcare.suredatalab.kr/",
  apiText : "api/",
  httpText : 'https://',
  server: window.location.hostname,
  port: "8080",
  webSocket : window.location.hostname,
  containerName : '/',
  
  dashboardUrl : "http://1.246.80.102:32492/grafana/d/01sH4kt4k/dashboard?orgId=1&theme=light",
  fileBrowserUrl : "http://1.246.80.102:32492/storage",
  monitoringClusterUrl : "http://1.246.80.102:32492/grafana/d/kube-cluster/kubernetes-cluster-prometheus?orgId=1&refresh=10s&theme=light",
  monitoringDataUrl : "http://1.246.80.102:32492/grafana/d/xVSnFKt4k/research-data-monitoring?orgId=1&theme=light",
  monitoringPublicCorporateDataUrl : " http://1.246.80.102:32492/grafana/d/a9QSc2KIz/data-monitoring?orgId=1&theme=light",
  logTrackingUrl : "http://1.246.80.102:32492/zipkin/",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.