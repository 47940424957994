
 <div class="error-wrapper">
    <div class="container"><img class="img-100" src="assets/images/other-images/sad.png" alt="">
      <div class="error-heading">
        <h6 class="headline font-secondary" style="font-size: 150px;">대 기 중</h6>
      </div>
      <div class="col-md-8 offset-md-2">
        <p class="sub-content">회원 승인 절차가 진행 중 입니다.</p>
      </div>
      <div><a class="btn btn-secondary-gradien btn-lg" routerLink=''>뒤로 가기</a></div>
    </div>
  </div>