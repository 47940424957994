import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { User } from '../data-object/User';
import { Filter } from '../data-object/Filter';
import { Users } from '../data-object/Users'
import { error } from 'console';
import { Router } from '@angular/router';
import { ErrorHandlingServiceService } from './error-handling-service.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlingService : ErrorHandlingServiceService,
  ) { }

  private Url = environment.httpText + environment.apiServer + environment.apiText + 'users';
  
  getUsers(filter : Filter) : Observable<Users> { //사용자 검색
    var url = `${this.Url}`;
    let httpParams = new HttpParams()

    if(filter.asc != null){
      httpParams = httpParams.set('asc', filter.asc)
    }

    if(filter.desc != null){
      httpParams = httpParams.set('desc', filter.desc)
    }

    if(filter.limit != null){
      httpParams = httpParams.set('limit', filter.limit )
    }

    if(filter.offset != null){
      httpParams = httpParams.set('offset', filter.offset )
    }

    if(filter.email != null){
      httpParams = httpParams.set('email', filter.email )
    }

    if(filter.name != null && filter.name != ''){
      httpParams = httpParams.set('name', filter.name )
    }

    if(filter.authority != null){
      httpParams = httpParams.set('authority', filter.authority )
    }

    if(filter.attributeName != null){
      httpParams = httpParams.set('attributeName', filter.attributeName )
    }

    if(filter.attributeValue != null){
      httpParams = httpParams.set('attributeValue', filter.attributeValue )
    }

    if(filter.status != null){
      for(let i = 0; i < filter.status.length; i++){
        httpParams = httpParams.append('status', filter.status[i])
      }
    }

    return this.http.get<Users>( url, { params: httpParams} ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<Users>(`GET /users`) )
    );
  }

  addUsers(filter : Filter){ //새로운 사용자 등록
    var url = `${this.Url}`;
    return this.http.post<User>( url, JSON.stringify(filter), httpOptions ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<User>(`POST /users`) )
    );
  }

  getUsersAuthorities(){ // 사용자 등급 변경 신청 리스트
    var url = `${this.Url}/authorities`;
    let httpParams = new HttpParams()


    return this.http.get<any>( url, {params: httpParams} ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`GET /users/authorities`) )
    );
  }

  updatedUsersAuthorities(user : User, reason : string){ // 사용자 등급 변경 신청 처리
    let parameter = {
      userId : user.userId,
      authorityId : user.authorityId,
      reason : reason
    }
    var url = `${this.Url}/authorities`;
    return this.http.post<User>( url, JSON.stringify(parameter), httpOptions ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<User>(`POST /users/authorities`) )
    );
  }

  getUsersForgot(email : string){ // 패스워드 찾기
    var url = `${this.Url}/forgot`;
    let httpParams = new HttpParams()
    .set('email', email)

    return this.http.get<any>( url, { params: httpParams} ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( /* this.errorHandlingService.handleError<any>(`GET /users/forgot`)  */
        error => {
        if (error.status === 404) {
          console.error('Not found error 처리');
          alert("가입되지않은 이메일입니다. 다시 확인해주세요.");
        } else {
          return throwError(error);
        }
        return throwError('GET /users/forgot');
      })
    );
  }

  getUsersGroups(email : string){ // 그룹(권한) 조회
    var url = `${this.Url}/groups`;

    return this.http.get<any>( url ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`GET /users/groups`) )
    );
  }

  addUsersGroups(){ // 사용자 그룹 추가
    let parameter = {
      authority: {
        typeId: '',
        typeName: '',
        level: 0,
        signupable: true
      },
      parent: [
        ''
      ],
      child: [
        ''
      ]
    }
    var url = `${this.Url}/groups`;
    return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`POST /users/groups`) )
    );
  }

  deleteUsersGroups(id : string){ // 사용자 그룹 제거
    var url = `${this.Url}/groups/${id}`;
    return this.http.delete<any>( url ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`DELETE /users/groups`) )
    );
  }

  chagneUsersPassword(parameter : any, token : string){ // 패스워드 변경
    var url = `${this.Url}/password`;
    if(token != ""){
      url += "?"+token
    }

    return this.http.put<any>( url, JSON.stringify(parameter), {observe: 'response'})
    
    /*return this.http.put<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`PUT /users/password`) )
    );*/
  }

  getMyProfile(){ // 내 정보 조회
    var url = `${this.Url}/profile`;

    return this.http.get<any>(url, { observe: "response" })

    return this.http.get<any>( url ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`GET /users/profile`) )
    );
  }

  deleteMyProfile(){ // 탈퇴
    var url = `${this.Url}/profile`;
    return this.http.delete<any>( url ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`DELETE /users/profile`) )
    );
  }
  
  addUserProfile(user : User){ // 회원 가입
    var url = `${this.Url}/profile`
    let parameter = user

    /*return this.http.post<any>( url, JSON.stringify(parameter), httpOptions )
    .subscribe(resp=>{
      console.log(resp)
    })*/

    return this.http.post<any>(url, JSON.stringify(parameter), {observe: 'response'});
    
    return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError((error) => {
        console.log('Returning caught observable'); 
        return throwError(() => new Error('The Error'));
      })
    );
  }

  updateMyProfile(user : User){ // 내 정보 수정
    var url = `${this.Url}/profile`
    let parameter = user

    return this.http.put<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`PUT /users/profile`) )
    );
  }

  changeUsersProfileAuthority(authorityID : string){ // 사용자 등급 변경 신청
    var url = `${this.Url}/profile`
    let parameter = {
      authorityID : authorityID
    }

    return this.http.patch<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`PUT /users/profile`) )
    );
  }

  getUsersStatistics(){ // 이용자 통계
    var url = `${this.Url}/statistics`
    let httpParams = new HttpParams()
    .set('timezone', '')
    .set('year', 1)
    .set('query', '')

    return this.http.get<any>( url, {params : httpParams} ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`GET /users/statistics`) )
    );
  }

  getUserInformation(userID : string){ // 사용자 정보 조회
    var url = `${this.Url}/${userID}`

    return this.http.get<any>( url ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`GET /users/{userId}`) )
    );
  }


  deleteUser(userID : string){ // 사용자 삭제
    var url = `${this.Url}/${userID}`
    
    return this.http.delete<any>( url ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`DELETE /users/{userId}`) )
    );
  }

  updateUser(user){ // 사용자 정보 수정
    var url = `${this.Url}/${user.userId}`
    
    return this.http.put<any>( url, JSON.stringify(user), httpOptions).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`PUT /users/{userId}`) )
    );
  }

  changeUserStatus(userID : string, status : string){ // 사용자 상태 변경
    var url = `${this.Url}/${userID}/status`
    let parameter = {
      status : status
    }

    return this.http.patch<any>( url, JSON.stringify(parameter), httpOptions).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.errorHandlingService.handleError<any>(`PATCH /users/{userId}/status`) )
    );
  }

  private log(message: string) {
    console.log(message)  
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
    console.error(error);
    this.log(`${operation} failed: ${error.message}`);
    
    // HTTP 에러 상태를 체크합니다.
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        // 401 에러일 경우 로그인 페이지로 리다이렉트합니다.
        alert("장시간 동작이 없어 자동으로 로그아웃 되었습니다.");
        this.router.navigate(['/auth/login']);
      }
    }

    return of(result as T);
    };
  }

}
