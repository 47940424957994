<ng-template #userAccount let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">계정 정보</h5>
        <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="eMail"> E-mail</label>
          <input class="form-control" id="eMail" type="text" readonly [(ngModel)]="user.email" disabled>
        </div>

        <div class="form-group col-md-12">
          <label for="authorityId"> 역할</label>
          <input class="form-control" id="authorityId" type="text" readonly [(ngModel)]="user.authority.typeName" disabled>
        </div>

        <div class="form-group col-md-12" *ngIf="!isUserInformation">
          <label for="authorityId"> 비밀번호 </label>
          <div>
            <a class="link" (click)="openChagnePassword()"> Change Password </a>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label for="surName"> 성 </label>
          <input class="form-control" id="surName" type="text" [(ngModel)]="user.surname">
          <div *ngIf="checkSurName" class="invalid-feedback">성을 입력해주세요.</div>
        </div>

        <div class="form-group col-md-8">
            <label for="userName"> 이름 </label>
            <input class="form-control" id="userName" type="text"  [(ngModel)]="user.username">
            <div *ngIf="checkName" class="invalid-feedback">이름을 입력해주세요.</div>
        </div>

        <div class="form-group col-md-4">
          <label for=""> 휴대전화 </label>
          <select class="form-control" [(ngModel)]="phoneNumberFirst">
              <option [value]="'010'">010</option>
              <option [value]="'011'">011</option>
              <option [value]="'016'">016</option>
              <option [value]="'017'">017</option>
              <option [value]="'018'">018</option>
              <option [value]="'019'">019</option>
          </select>
          <div *ngIf="checkPhoneNumber" class="invalid-feedback">휴대전화를 입력해주세요.</div>
        </div>

        <div class="form-group col-md-4">
            <label for=""> </label>
            <input class="form-control" style="margin-top: 8px;" type="text" [onlyNumbers] [(ngModel)]="phoneNumberSecond" [maxlength]="4">
        </div>

        <div class="form-group col-md-4">
            <label for=""> </label>
            <input class="form-control" style="margin-top: 8px;" type="text" [onlyNumbers] [(ngModel)]="phoneNumberThird" [maxlength]="4">
        </div>

        <div class="form-group col-md-12">
          <label for="company"> 회사</label>
          <input class="form-control" id="company" type="text" [(ngModel)]="user.attributes.company">
        </div>
      </div>
      <button class="btn gray" style="float: right;" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">닫기</button>
      <button class="btn blue" style="float: right; margin-right: 10px;" type="button" data-dismiss="modal" (click)="updateMyAccount()">수정</button>

    </div>
</ng-template>

