import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		// this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	rootMenuList(){
		return [
			{
				path: '/dashboard/manager', title: '대쉬보드', icon: 'home', type: 'link'
			},
	
			{
				title: '데이터 보기', icon: 'server', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/dataSearch/personal_data', title: '개인 맞춤 데이터', type: 'link' },
					{ path: '/dataSearch/corporate_data', title: '기업 데이터', type: 'link' },
					{ path: '/dataSearch/public_data', title: '공공 데이터', type: 'link' }
				]
			},

			{
				title: '상관관계 분석', icon: 'git-pull-request', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/correlationAnalysis/personal_data', title: '개인 맞춤 데이터', type: 'link' },
					{ path: '/correlationAnalysis/corporate_data', title: '기업 데이터', type: 'link' },
					/* { path: '/correlationAnalysis/public_data', title: '공공 데이터', type: 'link' } */
				]
			},
			{
				title: "거래 관리", icon: 'file-text', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/transaction-management/purchase', title: '개인맞춤데이터 구매 관리', type: 'link' },
					/* { path: '/transaction-management/corporate_purchase', title: '기업데이터 구매 관리', type: 'link' }, */
					{ path: '/transaction-management/sales', title: '개인맞춤데이터 판매 관리', type: 'link' },
					{ path: '/transaction-management/corporate_sales', title: '기업데이터 거래 관리', type: 'link' },
				]
			},
	
			{
				title: '데이터 관리', icon: 'package', type: 'sub', badgeType: 'success', active: true, children: [
					//{ path: '/dataManagement/data', title: '데이터 관리', type: 'link' },
					//{ path: '/dataManagement/admin_management', title: '데이터 관리', type: 'link' },
					//{ path: '/dataManagement/sales_information', title: '판매 관리', type: 'link' },
					//{ path: '/dataManagement/purchase_information', title: '구매 관리', type: 'link' },
					{ path: '/dataManagement/file_browser', title: '파일브라우저', type: 'link'},
					{ path: '/dataManagement/admin_management', title: '개인 맞춤 데이터', type: 'link' },
					{ path: '/dataManagement/data_management_corporate', title: '기업 데이터', type: 'link' },
					{ path: '/dataManagement/data_management_public', title: '공공 데이터', type: 'link' },
				]
			},
			/* {
				path: '/judge', title: '심사', icon: 'judge', type: 'link'
			}, */
			{
				title: '모니터링', icon: 'monitor', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/monitoring/cluster', title: '클러스터', type: 'link' },
					{ path: '/monitoring/data', title: '개인맞춤 데이터', type: 'link' },
					{ path: '/monitoring/public_corpordate_data', title: '기업/공공 데이터', type: 'link' },
				]
			},
			{
				path: '/logTracking', title: '로그 추적', icon: 'share', type: 'link'
			},
			{
				path: '/users', title: '사용자 관리', icon: 'users', type: 'link'
			},
		];
	}

	reviewerMenuList(){
		return [
			{
				path: '/judge', title: '심사', icon: 'judge', type: 'link'
			}
		];
	}

	providerMenuList(){
		return [
			{
				path: '/dashboard/producer', title: '대쉬보드', icon: 'home', type: 'link'
			},
	
			{
				title: '데이터 보기', icon: 'server', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/dataSearch/personal_data', title: '개인 맞춤 데이터', type: 'link' },
					{ path: '/dataSearch/corporate_data', title: '기업 데이터', type: 'link' },
					{ path: '/dataSearch/public_data', title: '공공 데이터', type: 'link' }
				]
			},

			{
				title: '상관관계 분석', icon: 'git-pull-request', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/correlationAnalysis/personal_data', title: '개인 맞춤 데이터', type: 'link' },
					{ path: '/correlationAnalysis/corporate_data', title: '기업 데이터', type: 'link' },
					/* { path: '/correlationAnalysis/public_data', title: '공공 데이터', type: 'link' } */
				]
			},

			{
				title: "거래 관리", icon: 'file-text', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/transaction-management/purchase', title: '개인맞춤데이터 구매 관리', type: 'link' },
					{ path: '/transaction-management/corporate_purchase', title: '기업데이터 구매 관리', type: 'link' },
					{ path: '/transaction-management/sales', title: '개인맞춤데이터 판매 관리', type: 'link' },
					{ path: '/transaction-management/corporate_sales', title: '기업데이터 판매 관리', type: 'link' },
				]
			},

			{
				title: "데이터 관리", icon: 'package', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/dataManagement/data_management_corporate', title: '기업 데이터', type: 'link' },
					{ path: '/dataManagement/data_consistency_check', title: '데이터 정합성 검사', type: 'link' },
				]
				
			},
		];
	}

	userMenuList(){
		return [
			{
				path: '/dashboard/guest', title: '대쉬보드', icon: 'home', type: 'link'
			},
	
			{
				title: '데이터 보기', icon: 'server', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/dataSearch/personal_data', title: '개인 맞춤 데이터', type: 'link' },
					{ path: '/dataSearch/corporate_data', title: '기업 데이터', type: 'link' },
					{ path: '/dataSearch/public_data', title: '공공 데이터', type: 'link' }
				]
			},

			{
				title: '상관관계 분석', icon: 'git-pull-request', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/correlationAnalysis/personal_data', title: '개인 맞춤 데이터', type: 'link' },
					{ path: '/correlationAnalysis/corporate_data', title: '기업 데이터', type: 'link' },
					/* { path: '/correlationAnalysis/public_data', title: '공공 데이터', type: 'link' } */
				]
			},
			{
				title: "거래 관리", icon: 'file-text', type: 'sub', badgeType: 'success', active: true, children: [
					{ path: '/transaction-management/purchase', title: '개인맞춤데이터 구매 관리', type: 'link' },
					{ path: '/transaction-management/corporate_purchase', title: '기업데이터 구매 관리', type: 'link' },
				]
			},
		];
	}
}
