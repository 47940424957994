import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //관리자
    //let token = "bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk1NjRmMzI5Y2ZjYjVjODUwODI1NGVhYzE0NjJlYWQ3OGE5NTE4MmYifQ.eyJpc3MiOiJodHRwczovL3dlbGNhcmUuc3VyZWRhdGFsYWIua3IvZGV4Iiwic3ViIjoiQ2dSeWIyOTBFZ2RrWldaaGRXeDAiLCJhdWQiOiJ3ZWxjYXJlLWF1dGgiLCJleHAiOjE3MTA0ODYyMzgsImlhdCI6MTcxMDM5OTgzOCwiYXRfaGFzaCI6InJqRWNSSWpXWTM1SVg1aDFfbU1KMEEiLCJlbWFpbCI6Im1hbmFnZXJAc3VyZWRhdGFsYWIuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiLsi5zsiqTthZwg6rSA66as7J6QIiwiZmVkZXJhdGVkX2NsYWltcyI6eyJjb25uZWN0b3JfaWQiOiJkZWZhdWx0IiwidXNlcl9pZCI6InJvb3QifX0.zYDYv_D8JTYqtwlQgcIgyWBCyxCeyykCew0RDR5We92SrbDxXS4dD089pSOpHV3YODMk2fmRSW3m5VNBZrMCL09Jr3-znZvrEEMr8LwmcOkZ-q0bPvjJIJVA0mWhsHZUP-V8nXo8p4wVvKvRARIA4xgSx9LnwbTK3gWuXAv76AVOQwwCzD6cXV8oo9mQYoDKt2P4wtO-pfhbBUoRozDa8TslXgqUVulwrWkbTmGQVgk9ed5gMkvz-qR3kEME3ZcoTfTFBqtTsVWtQHnxeiOIdxsUma5WGlf3w3pwx8qsICzQ9Y_-zxH0KnxwhLurcOzxT5FlRp6UZq_S7rSzOBVU5Q";
    //판매자
    //let token = "bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk1NjRmMzI5Y2ZjYjVjODUwODI1NGVhYzE0NjJlYWQ3OGE5NTE4MmYifQ.eyJpc3MiOiJodHRwczovL3dlbGNhcmUuc3VyZWRhdGFsYWIua3IvZGV4Iiwic3ViIjoiQ2dod2NtOTJhV1JsY2hJSFpHVm1ZWFZzZEEiLCJhdWQiOiJ3ZWxjYXJlLWF1dGgiLCJleHAiOjE3MTA0NzIzODAsImlhdCI6MTcxMDM4NTk4MCwiYXRfaGFzaCI6ImJTdDA4aXhOY0thZF9zcUxuYmJaVXciLCJlbWFpbCI6InByb3ZpZGVyQHdlbGNhcmUub3JnIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiLsoJzqs7XsnpAiLCJmZWRlcmF0ZWRfY2xhaW1zIjp7ImNvbm5lY3Rvcl9pZCI6ImRlZmF1bHQiLCJ1c2VyX2lkIjoicHJvdmlkZXIifX0.mHtp4FjUB3XiNss0yHSPsqm9pJwlhRZVMqzs0QWaVf22ysUrDzlxKMRGXEnl9UVI1vI0-0NSFVePLd1ArU-36TmeNA8F9ZxcETNoCQBCeqUsUyaeYoGONb6oBF7eitI1sD7xTgAUUCZrrSsZBNy8HPP2FXwzUSX9KcCCxlW-VKCwrePIeR2-l6mJ28T286ArBjNryPNqtIJSwfev1CjbDFFozYLRhzEZzkkf3uPQVQxvWHFFkeHfrP7w3w0M66NE75kcIFO9vxQi73a8tHTTGdcCcr0nu0SWQGin92XV8L2w1wyo0pqhH_pN8nTUOQTCdG52wSbyu16kRUI9zw-D6w";
    //사용자
    //let token ="bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk1NjRmMzI5Y2ZjYjVjODUwODI1NGVhYzE0NjJlYWQ3OGE5NTE4MmYifQ.eyJpc3MiOiJodHRwczovL3dlbGNhcmUuc3VyZWRhdGFsYWIua3IvZGV4Iiwic3ViIjoiQ2dSMWMyVnlFZ2RrWldaaGRXeDAiLCJhdWQiOiJ3ZWxjYXJlLWF1dGgiLCJleHAiOjE3MTA0ODU5NzEsImlhdCI6MTcxMDM5OTU3MSwiYXRfaGFzaCI6Im9vY0Y3V2NVSzhQYXpuYkN6TVZiMWciLCJlbWFpbCI6InVzZXJAd2VsY2FyZS5vcmciLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IuydvOuwmOyCrOyaqeyekCIsImZlZGVyYXRlZF9jbGFpbXMiOnsiY29ubmVjdG9yX2lkIjoiZGVmYXVsdCIsInVzZXJfaWQiOiJ1c2VyIn19.sMthbbpUyRc5kdnLrxcDH5vwo3daq1vtvaNv1rB9nRcr-WoesD3GI6klCBzn395HUajMfrqTwNOFHP9uOoR_L5UqyIb76q4KSxxzAxplM-ScYugWx6M72jA0E1YcwUPOTtSoNPFrPlQ1gJd8L6iuIfku9ZC5rTowybaYCLUvYFUcoEgILims2kyYE7jqGjsnNtatX5szAXwYfY5dqwC4mWc6iwaLm79TGxP-MxNJnFl9PMzvTH6cZTkqu2e9lMXmGqYL-ntTegbDHSRpr33BCb40Q6p3V2QoSjK8dEx_8Lfi0j6-mj3MkYPIIorXM24wxAuK-yRCN5Ee_1zfpbRC4Q";
    //심사위원
    //let token = "bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk1NjRmMzI5Y2ZjYjVjODUwODI1NGVhYzE0NjJlYWQ3OGE5NTE4MmYifQ.eyJpc3MiOiJodHRwczovL3dlbGNhcmUuc3VyZWRhdGFsYWIua3IvZGV4Iiwic3ViIjoiQ2doeVpYWnBaWGRsY2hJSFpHVm1ZWFZzZEEiLCJhdWQiOiJ3ZWxjYXJlLWF1dGgiLCJleHAiOjE3MTA0NzI0NTYsImlhdCI6MTcxMDM4NjA1NiwiYXRfaGFzaCI6IllJdWQybWtCbUM3VmpXNktrcVc0YlEiLCJlbWFpbCI6InJldmlld2VyQHN1cmVkYXRhbGFiLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoi7JyE7JuQIiwiZmVkZXJhdGVkX2NsYWltcyI6eyJjb25uZWN0b3JfaWQiOiJkZWZhdWx0IiwidXNlcl9pZCI6InJldmlld2VyIn19.pEk9nbS0swoCkKWXEOq2pqFbS2dp3Cq0CarUt8FNISYQ_O1ArdkN_7QiLWiQakKs8oaF8pSML3KrQvzoeD1HT8ajPXP9hSHEldTw4TtHSMtpqpxC3LZgqszp1aKiYEE9LAzuNee4O-a86oKd0ac6-IgOWg3rW-sHfCPoRz0aAPwjxyi3C8zb7rbP5kZbVDMVabqe8GFzKo-9KVa9JxS0oi3pfhiK5gkwFiOJzKO68EIgjGgb6-TTVCuOm3FYeCAagAdRRIJzUR7nGCoIQ_yLyXR_inpDgpjsNwT8KHQzmub7gnqeMAMIRUZtCL7L1F56CDQRXioYncgWenZnD6FH8w";
    const authReq = req.clone({
      //headers: req.headers.set('Authorization', token)
    });

    return next.handle(authReq)
  }
}
