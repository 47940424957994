<ng-template #signUp let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">회원가입</h5>
        <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
          <div class="row">
            
            <div class="form-group col-md-12">
                <label for="eMail"> E-mail <span> * </span> </label>
                <input class="form-control" id="eMail" type="text" [(ngModel)]="user.email">
                <div *ngIf="checkEmail" class="invalid-feedback">E-mail 입력을 확인해 주세요.</div>
            </div>

            <div class="form-group col-md-4">
                <label for="surName"> 성 <span> * </span> </label>
                <input class="form-control" id="surName" type="text" [(ngModel)]="user.surname">
                <div *ngIf="checkSurName" class="invalid-feedback">성을 입력해주세요.</div>
            </div>

            <div class="form-group col-md-8">
                <label for="userName"> 이름 <span> * </span> </label>
                <input class="form-control" id="userName" type="text"  [(ngModel)]="user.username">
                <div *ngIf="checkName" class="invalid-feedback">이름을 입력해주세요.</div>
            </div>

            <!--
        
            <div class="form-group col-md-3">
                <label for=""> 국가  </label>

                <select class="form-control" [(ngModel)]="user.contact.e164Number">
                    <option [value]="">-</option>
                    <option [value]="82">대한민국</option>
                </select>
            </div>

            <div class="form-group col-md-3">
                <label for=""> 지역 </label>
                <select class="form-control" [(ngModel)]="user.contact.shortCode.regionCode">
                    <option [value]="">-</option>
                    <option [value]="KR-11">서울</option>
                    <option [value]="KR-26">부산</option>
                    <option [value]="KR-27">대구</option>
                    <option [value]="KR-28">인천</option>
                    <option [value]="KR-29">광주</option>
                    <option [value]="KR-30">대전</option>
                    <option [value]="KR-31">울산</option>
                    <option [value]="KR-50">세종</option>
                    <option [value]="KR-41">경기도</option>
                    <option [value]="KR-42">강원도</option>
                    <option [value]="KR-43">충청북도</option>
                    <option [value]="KR-44">충청남도</option>
                    <option [value]="KR-45">전라북도</option>
                    <option [value]="KR-46">전라남도</option>
                    <option [value]="KR-47">경상북도</option>
                    <option [value]="KR-48">경상남도</option>
                    <option [value]="KR-49">제주</option>
                </select>
            </div>

            <div class="form-group col-md-6">
                <label for=""> 전화번호 </label>
                <input class="form-control"  type="text" [onlyNumbers] [(ngModel)]="user.contact.shortCode.number" [maxlength]="11">
            </div>
-->

            <div class="form-group col-md-4">
                <label for=""> 휴대전화 <span> * </span> </label>

                <select class="form-control" [(ngModel)]="phoneNumberFirst">
                    <option [value]="'010'">010</option>
                    <option [value]="'011'">011</option>
                    <option [value]="'016'">016</option>
                    <option [value]="'017'">017</option>
                    <option [value]="'018'">018</option>
                    <option [value]="'019'">019</option>
                </select>
                <div *ngIf="checkPhoneNumber" class="invalid-feedback">휴대전화를 입력해주세요.</div>
            </div>

            <div class="form-group col-md-4">
                <label for=""> 　</label>
                <input class="form-control" style="margin-top: 8px;" type="text" [onlyNumbers] [(ngModel)]="phoneNumberSecond" [maxlength]="4">
            </div>

            <div class="form-group col-md-4">
                <label for=""> </label>
                <input class="form-control" style="margin-top: 8px;" type="text" [onlyNumbers] [(ngModel)]="phoneNumberThird" [maxlength]="4">
            </div>

            <div class="form-group col-md-12">
                <label for="organization"> 소속 </label>
                <input class="form-control" id="organization" type="text" [(ngModel)]="user.attributes.company">
            </div>

            <div class="form-group col-md-12">
                <label for=""> 역할 <span> * </span> </label>
                <div style="display: flex;">
                    <div class="radio radio-primary ms-3">
                        <input id="user" type="radio" name="authority" value="user" checked [(ngModel)]="user.authorityId">
                        <label for="user">사용자</label>
                    </div>
                    <div class="radio radio-primary ms-3">
                        <input id="provider" type="radio" name="authority" value="provider" [(ngModel)]="user.authorityId">
                        <label for="provider">제공자</label>
                    </div>
                    <div class="radio radio-primary ms-3">
                        <input id="manager" type="radio" name="authority" value="root" [(ngModel)]="user.authorityId">
                        <label for="manager">평가위원</label>
                    </div>
                </div>

                <div *ngIf="checkAuthority" class="invalid-feedback">권한을 입력해주세요.</div>
            </div>

            <!--<div class="form-group col-md-12">
                <div class="checkbox p-0">
                    <input id="consentCheckBox1" type="checkbox" [(ngModel)]="consentCheckBox1">
                    <label class="text-muted" for="consentCheckBox1" >웰케어 사용 약관 동의</label>
                </div>

                <div class="checkbox p-0">
                    <input id="consentCheckBox2" type="checkbox" [(ngModel)]="consentCheckBox2">
                    <label class="text-muted" for="consentCheckBox2">개인정보 수집 및 이용 동의</label>
                </div>
            </div>


            <div class="form-group col-md-4">
                <label for=""> additionalProp1 </label>
                <input class="form-control" type="text" [(ngModel)]="user.attributes.additionalProp1">
            </div>

            <div class="form-group col-md-4">
                <label for=""> additionalProp2 </label>
                <input class="form-control" type="text" [(ngModel)]="user.attributes.additionalProp2">
            </div>

            <div class="form-group col-md-4">
                <label for=""> additionalProp3 </label>
                <input class="form-control" type="text" [(ngModel)]="user.attributes.additionalProp3">
            </div>
            -->
            
          </div>

          <button class="btn gray" style="float: right;" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">닫기</button>
          <button class="btn btn-primary" style="float: right; margin-right: 10px;" type="button" data-dismiss="modal" (click)="application()">가입 신청</button>

    </div>

</ng-template>