
<ng-template #writeReviewMetadata let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">심사 의견</h5>
        <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" #closebutton (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <div class="form-group col-md-12">
            <label class="subTitle"> 메타데이터 </label>

            <div class="metadataContainer">
                <div class="metadataRow flex-grow-1">
                    <div class="cellLabel flex-grow-1">데이터 이름</div>
                    <div class="cellValue flex-grow-1">공공데이터 토양오염도 현황</div>
                    <div class="cellLabel flex-grow-1">데이터 출처</div>
                    <div class="cellValue flex-grow-1">한국환경산업기술원</div>
                </div>

                <div class="metadataRow flex-grow-1">
                    <div class="cellLabel flex-grow-1">데이터 형식</div>
                    <div class="cellValue flex-grow-1">CSV</div>
                    <div class="cellLabel flex-grow-1">데이터 구축년도</div>
                    <div class="cellValue flex-grow-1">2020</div>
                </div>
                <div class="metadataRow flex-grow-1">
                    <div class="cellLabel flex-grow-1">데이터 유형</div>
                    <div class="cellValue flex-grow-1">텍스트</div>
                    <div class="cellLabel flex-grow-1">데이터 구축량</div>
                    <div class="cellValue flex-grow-1">10 건 / 50 MB</div>
                </div>
                <div class="metadataRow flex-grow-4">
                    <div class="cellLabel flex-grow-1">데이터 설명</div>
                    <div class="cellValue flex-grow-4"> 2011 ~ 2020년간 토양 오염도 측정 기준과 초과업체 수를 수집한 데이터 </div>
                </div>
            </div>



        </div>

        <div class="form-group col-md-12">
            <label class="subTitle"> 데이터 파일 내 포함된 컬럼들 </label>

            <div class="metadataColumContainer">
                <div class="metadataRow">
                    <div class="cellLabel flex-grow-1">컬럼명</div>
                    <div class="cellLabel flex-grow-3">컬럼 설명</div>
                </div> 
                
                <div class="metadataRow">
                    <div class="cellLabel flex-grow-1 justify-content-start">구분</div>
                    <div class="cellValue flex-grow-3 justify-content-start">측정연도 (2011 ~2020)</div>
                </div> 

                <div class="metadataRow">
                    <div class="cellLabel flex-grow-1 justify-content-start">실태조사우려기준</div>
                    <div class="cellValue flex-grow-3 justify-content-start">실태조사에서 우려평가 기준</div>
                </div> 

                <div class="metadataRow">
                    <div class="cellLabel flex-grow-1 justify-content-start">실태조사대책기준</div>
                    <div class="cellValue flex-grow-3 justify-content-start">실태조사에서 대책마련 기준</div>
                </div> 

                <div class="metadataRow">
                    <div class="cellLabel flex-grow-1 justify-content-start">토양측정망우려기준</div>
                    <div class="cellValue flex-grow-3 justify-content-start">토양측정에서 우려평가 기준</div>
                </div> 

                <div class="metadataRow">
                    <div class="cellLabel flex-grow-1 justify-content-start">특정토양오염 관리대상시설</div>
                    <div class="cellValue flex-grow-3 justify-content-start">기준 초과 업체 수</div>
                </div> 
            </div>
        </div>

        <div class="form-group col-md-12">
            <label class="subTitle"> 의견 </label>

            <div class="commentTextBox">
                <textarea class="commentText" placeholder="심사 의견을 작성하시고 심사 결과로 '등록 승인' 또는 '등록 반려' 버튼을 눌러 주세요."></textarea>
            </div>
        </div>

        <div class="btnBox">
            <div class="btn blue" (click)="approveMetadata()"> 등록 승인 </div>
            <div class="btn red" style="margin-left : 10px" (click)="rejectMetadata()"> 등록 반려 </div>
        </div>
    </div>
</ng-template>