import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlingServiceService } from './error-handling-service.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlingService : ErrorHandlingServiceService,
  ) { }

  //private Url = environment.httpText + environment.server +':'+environment.port+environment.containerName+'/users';
    private Url = environment.httpText + environment.apiServer + environment.apiText + 'statistics';

    getDataCondition(){ // 데이터 현황 조회
      let url = `${this.Url}/conditions`
      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /statistics/conditions`) )
      );
    }

    getDataCorporateCondition(){ // 기업데이터 현황 조회
      let url = `${this.Url}/companydata/summary`;
      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /statistics/companydata/summary`) )
      );
    }

    getDataCorporatePurchasesCount(){ //기업데이터 데이터크기 별 판매 수
      let url = `${this.Url}/companydata/summary`;
      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /statistics/companydata/summary`) )
      );
    }
    
    getDataCorporateTrend(){ // 기업데이터 제공/판매 추이
      var url = `${this.Url}/companydata/trend`;
      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /statistics/companydata/trend`) )
      );
    }

    getDataTrans(unit : string){ // 데이터 제공량/ 판매량 추이
      var url = `${this.Url}/trend`
      let params = new HttpParams()
      .set("unit", unit)

      return this.http.get<any>( url, {params : params}).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /statistics/trend`) )
      );
    }

    getCategories(category_code : string, subject : any ){ // 카테고리 통계 정보 ??
      var url = `${this.Url}/categories/${category_code}`

      let parameter = {
        filters: [{
          subjectCode : subject.subjectCode,
          compare : subject.compare,
          value : subject.value+""
        }]
      }
      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /statistics/categories/{category_code}`) )
      );
    }

    getBycount(category_code : string, subject : any){ // 
      var url = `${this.Url}/bycount/${category_code}`

      /*let parameter = {
        'subjectCode' : subject.subjectCode,
        'filter.subjectCode' : subject.subjectCode,
        'filter.compare' : subject.compare,
        'filter.value' : subject.value
      }*/

      let parameter = {
        subjectCode : subject.subjectCode,
        filter : {
          subjectCode : subject.subjectCode+"",
          compare : subject.compare+"",
          value : subject.value+""
        }
      }

      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /statistics/bycount/{category_code}`) )
      );


      /*return this.http.get<any>( url, {params : params}).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.errorHandlingService.handleError<any>(`GET /statistics/bycount/{category_code}`) )
      );*/
    }



    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      
      // HTTP 에러 상태를 체크합니다.
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // 401 에러일 경우 로그인 페이지로 리다이렉트합니다.
          alert("장시간 동작이 없어 자동으로 로그아웃 되었습니다.");
          this.router.navigate(['/auth/login']);
        }
      }
      
      return of(result as T);
      };
    }

}
