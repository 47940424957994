<ng-template #addDetailCategory let-modal>
  <div class="modal-header" style="padding: 0 21px; border-bottom: 1px solid #d3d3d3;">
        
    <div class="select-list-box" > Home </div>
    <div class="select-list-box"> / </div>
    <div class="select-list-box">{{mainCategoryDisplayName}}</div>
    <div class="select-list-box"> / </div>
    <div class="select-list-box">{{middleCategory.displayName}}</div>

    <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="">
          
      <div class="row">
        <div class="col-md-12">
            <input style="margin: 5px 0;" type="text" placeholder="Search" class="form-control" [(ngModel)]="filter.subjectName" (ngModelChange)="getCategorySubject()"/>
            <div class="table-responsive">
              <table class="table">
                  <thead>
                      <tr>
                        <th style="text-align: start;" >소분류 항목</th>
                        <!--<th scope="col" sortable="column" >value type</th>
                        <th scope="col" sortable="column" >description</th>-->
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let categories of subCategory.categories" (click)="clickSubCategory(categories)" [ngClass]="{'selectRow': selectCategory == categories}" (dblclick)="addCategory()" style="cursor: pointer;">
                          <td scope="row"  style="display: flex; text-align: start;">
                            <div style="width: 25px;"></div> 
                            
                            <div style="width: 85%;">
                              {{categories.displayName}}

                            </div>
                          
                            <div style="width: calc(15% - 25px);">
                              {{categories.valueType}}
                            </div>
                          </td>
                          <!--<td>{{categories.valueType}}</td>
                          <td></td>-->
                      </tr>
                      <div style="border-bottom: 1px solid #d3d3d3; margin-bottom: 16px;"></div>
                  </tbody>
              </table>
              <div class="d-flex justify-content-center p-2">
                <nz-pagination 
                  nzShowSizeChanger
                  [nzTotal]="subCategory.count"
                  [(nzPageIndex)]="page"
                  [(nzPageSize)]="getsetPageSize">
                </nz-pagination>
              </div>
          </div>

        </div>
      </div>
      
      <!--<button style="float : right;" class="btn btn-primary right" type="button" data-dismiss="modal" (click)="addCategory()">선택</button>-->
    </form>
  </div>
</ng-template>