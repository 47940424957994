import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ViewChild, TemplateRef, Inject, PLATFORM_ID, Output, EventEmitter } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Filter } from 'src/app/shared/data-object/Filter';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-add-detail-category',
  templateUrl: './add-detail-category.component.html',
  styleUrls: ['./add-detail-category.component.scss']
})
export class AddDetailCategoryComponent implements OnInit {
  @ViewChild("addDetailCategory", { static: false }) addDetailCategory: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  @Output() detailCategory = new EventEmitter<any>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private metadataService :MetadataService,
    private utilSerivce : UtilService
  ) { }

  mainCategoryDisplayName : any;
  middleCategory : any
  
  filter : Filter = new Filter()

  subCategory : any = {
    count : 0
  }

  currentPage = 1;
  pageSize : number = 10;

  get page(){return this.currentPage}
  set page(page:number){
    console.log(page)
    this.currentPage = page
    this.filter.offset = ((this.currentPage-1) * 10)
    this.getCategorySubject()
  }

  get getsetPageSize(){return this.pageSize}
  set getsetPageSize(pageSize:number){
    this.pageSize = pageSize
    this.filter.offset = ((this.currentPage-1) * this.pageSize)
    this.getCategorySubject()
  }

  selectCategory : any = null


  ngOnInit(): void {
    
  }



  openModal(mainCategoryDisplayName, middleCategory) {
    this.mainCategoryDisplayName = mainCategoryDisplayName
    this.middleCategory = middleCategory
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.addDetailCategory, { 
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    this.selectCategory = null
    this.subCategory = {count : 0}
    this.currentPage = 1;
    this.filter = new Filter()
    this.getCategorySubject()

  }

  getCategorySubject(){
    this.filter.limit = 10
    this.metadataService.getCategorySubject(this.filter, this.middleCategory.categoryCode).subscribe(result=>{
      console.log(result)
      this.subCategory = result
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clickSubCategory(category){
    this.selectCategory = category
  }

  addCategory(){

    if(this.selectCategory == null){
      this.utilSerivce.openPopup("소분류 추가","소분류를 선택해 주세요.","info")
      return;
    }

    this.detailCategory.emit(this.selectCategory)
    this.modalService.dismissAll()
  }

}
