import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '../../data-object/Contact';
import { User } from '../../data-object/User';
import { UserService } from '../../services/user.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  constructor(
    private userService : UserService,
    private utilService : UtilService,
    private router: Router,
  ) { }

  phoneNumberFirst = ""
  phoneNumberSecond = ""
  phoneNumberThird = ""

  checkEmail : boolean = false;
  checkName : boolean = false;
  checkSurName : boolean = false
  checkPhoneNumber : boolean = false;
  checkAuthority : boolean = false;

  show : boolean =false
  user : User = new User()

  isSignUp : boolean = false;

  ngOnInit(): void {
  }

  application(){
    console.log(this.user)

    let regExp = /^[0-9a-z]([-_.]?[0-9a-z])*@[0-9a-z]([-_.]?[0-9a-z])*.[a-z]{2,3}$/i;

    if(!this.user.email || this.user.email.match(regExp) == null){
      this.checkEmail = true
      return
    }else {
      this.checkEmail = false
    }

    if(!this.user.surname || this.user.surname == ""){
      this.checkSurName = true;
      return
    }else {
      this.checkSurName = false;
    }

    if(!this.user.username || this.user.username == ""){
      this.checkName = true
      return
    }else {
      this.checkName = false
    }

    if(this.phoneNumberFirst == "" || this.phoneNumberSecond.length < 4 || this.phoneNumberThird.length < 4){
      this.checkPhoneNumber = true
      return
    }else {
      this.checkPhoneNumber = false
    }

    if(!this.user.authorityId || this.user.authorityId == ""){
      this.checkAuthority = true
      return
    }else {
      this.checkAuthority = false
    }

    /*if(!this.consentCheckBox1 || !this.consentCheckBox2){
      return
    }*/

    this.user.contact = new Contact()

    this.user.contact.e164Number = '+82' + this.phoneNumberFirst  + this.phoneNumberSecond + this.phoneNumberThird

    //this.user.contact.extension = this.phoneNumberFirst + "-" + this.phoneNumberSecond + "-" + this.phoneNumberThird

    this.user.uid = this.user.email.substring(0,this.user.email.indexOf('@'));

    this.userService.addUserProfile(this.user).subscribe(resp => {
      console.log(resp.headers);
      this.utilService.openPopup("회원가입", "회원가입 신청이 완료되었습니다.", "success")
      this.isSignUp = true;
    },
    error=>{
      console.log(error)
      this.utilService.openPopup("회원가입", error.headers.get('grpc-message'), "error")
    })

    /*this.userService.addUserProfile(this.user).subscribe(
      response=>{
        console.log(response)
      if(response){

      }}, error=>{
      console.log(error)
    })*/
  }

  moveLogin(){
    this.router.navigate(['/auth/login']);
  }

}
