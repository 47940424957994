import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  {
    path: 'dataSearch',
    loadChildren: () => import('../../components/dataSearch/data-search.module').then(m => m.DataSearchModule)
  },

  {
    path: 'newDataSearch',
    loadChildren: () => import('../../components/new-data-search/new-data-search.module').then(m => m.NewDataSearchModule)
  },

  {
    path: 'correlationAnalysis',
    loadChildren: () => import('../../components/correlation-analysis/correlation-analysis.module').then(m => m.CorrelationAnalysisModule)
  },

  {
    path: 'dataRegistration',
    loadChildren: () => import('../../components/data-registration/data-registration.module').then(m => m.DataRegistrationModule)
  },

  {
    path: 'dataManagement',
    loadChildren: () => import('../../components/data-management/data-management.module').then(m => m.DataManagementModule)
  },

  {
    path: 'logTracking',
    loadChildren: () => import('../../components/log-tracking/log-tracking.module').then(m => m.LogTrackingModule)
  },

  {
    path: 'monitoring',
    loadChildren: () => import('../../components/monitoring/monitoring.module').then(m => m.MonitoringModule)
  },

  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule)
  },

  {
    path: 'judge',
    loadChildren: () => import('../../components/judge/judge.module').then(m => m.JudgeModule)
  },

  {
    path: 'transaction-management',
    loadChildren: () => import('../../components/transaction-management/transaction-management.module').then(m => m.TransactionManagementModule)
  },

];
