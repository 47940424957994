import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ViewChild, TemplateRef, Inject, PLATFORM_ID, Output, EventEmitter } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchasesService } from 'src/app/shared/services/purchases.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-buy-data',
  templateUrl: './buy-data.component.html',
  styleUrls: ['./buy-data.component.scss']
})
export class BuyDataComponent implements OnInit {
  @ViewChild("buyData", { static: false }) buyData: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  @Output() detailCategory = new EventEmitter<any>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private purchasesService : PurchasesService,
    private utilSerivce : UtilService
  ) { }

  purchasesInfo : any = {
    providers : []
  }

  ngOnInit(): void {

  }

  mainCategory : any;
  middleCategory : any

  currentDate : Date = new Date();

  email : string = ""

  

  openModal(mainCategory, middleCategory) {
    console.log(middleCategory)
    this.mainCategory = mainCategory
    this.middleCategory = middleCategory

    this.purchasesInfo = {
      providers : []
    }

    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.buyData, { 
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    this.purchasesService.getPurchasesResearchdataCategoryCategoryCode(middleCategory.categoryCode).subscribe(result=>{
      console.log(result)
      if(result){
        this.purchasesInfo = result
      }
    })
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  setBuyData(){
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    if(this.email == "" ){
      this.utilSerivce.openPopup("데이터 구매","E-Mail을 입력해주세요.","info")
      return;
    }

    if(!regex.test(this.email)){
      this.utilSerivce.openPopup("데이터 구매","E-Mail형식이 맞지않습니다.","info")
      return;
    }

    this.purchasesService.postPurchaseResearchdata(this.middleCategory.categoryCode,this.purchasesInfo, this.email ).subscribe(result=>{
      console.log(result)
      if(result){
        this.utilSerivce.openPopup("데이터 구매","데이터를 구매신청 하였습니다.","success")
        this.modalService.dismissAll()
      }else {
        this.utilSerivce.openPopup("데이터 구매","데이터를 구매에 실패하였습니다.","error")
      }
    })
  }
}
