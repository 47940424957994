<ng-template #resetPassword let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">비밀번호 변경</h5>
        <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">

        <div class="form-group col-md-12">
            <label for="eMail"> 새 비밀번호 </label>
            <input class="form-control" [type]="newPasswordSohw ? 'text' : 'password'" placeholder="new password" [(ngModel)]="newPassword">
            <div class="show-hide" (click)="newPasswordSohw = !newPasswordSohw" *ngIf="!newPasswordSohw"><span class="show"></span></div>
            <div class="show-hide" (click)="newPasswordSohw = !newPasswordSohw" *ngIf="newPasswordSohw"><span class="Hide"></span></div>
        </div>

        <div class="form-group col-md-12">
            <label for="eMail"> 새 비밀번호 확인 </label>
            <input class="form-control" [type]="newPasswrodRepeatedShow ? 'text' : 'password'" placeholder="new password repeated" [(ngModel)]="newPasswrodRepeated">
            <div class="show-hide" (click)="newPasswrodRepeatedShow = !newPasswrodRepeatedShow" *ngIf="!newPasswrodRepeatedShow"><span class="show"></span></div>
            <div class="show-hide" (click)="newPasswrodRepeatedShow = !newPasswrodRepeatedShow" *ngIf="newPasswrodRepeatedShow"><span class="Hide"></span></div>
        </div>


        <button class="btn btn-primary" style="float: right;" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">취소</button>
        <button class="btn btn-primary" style="float: right; margin-right: 10px;" type="button" data-dismiss="modal" (click)="updatePassword()">변경</button>

    </div>
</ng-template>