import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
const Swal = require('sweetalert2')
@Component({
  selector: 'app-write-review-metadata',
  templateUrl: './write-review-metadata.component.html',
  styleUrls: ['./write-review-metadata.component.scss']
})
export class WriteReviewMetadataComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
  ) { }

  @ViewChild("writeReviewMetadata", { static: false }) writeReviewMetadata: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  metadata : any
  ngOnInit(): void {

  }

  openModal(data : any) {
    console.log(data)
    this.metadata = data;
    
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.writeReviewMetadata, { 
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  approveMetadata(){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn blue',
        cancelButton: 'btn gray'
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons.fire({
      title: 'xxxx에 대한 심사 결과',
      text: "등록 승인합니다.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: '승인',
      cancelButtonText: '취소',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        console.log("??")
      }
    })
  }

  rejectMetadata(){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn red',
        cancelButton: 'btn gray'
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons.fire({
      title: 'xxxx에 대한 심사 결과',
      text: "등록 반려합니다.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: '반려',
      cancelButtonText: '취소',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        console.log("??")
      }
    })
  }

}
