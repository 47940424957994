import { Component, OnInit, ViewChild } from '@angular/core';
import { ChagnePasswordComponent } from 'src/app/shared/modal/chagne-password/chagne-password.component';
import { UserAccountComponent } from 'src/app/shared/modal/user-account/user-account.component';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  public user : any = {name : ""}
  public profileImg: 'assets/images/dashboard/profile.jpg';

  @ViewChild("userAccount") userAccount: UserAccountComponent;
  @ViewChild("chagnePassword") chagnePassword: ChagnePasswordComponent;

  isProfileOnOff : boolean = false;
  constructor(public authService: AuthService,
    private userService : UserService) {
    this.user = JSON.parse(localStorage.getItem('user'))
  }

  myAccount(){
    this.userAccount.openModal(null)
  }


  logoutFunc() {
    this.authService.SignOut();
  }

  passwordChangeOpen(event : any){
    console.log(event)
    if(event){
      this.chagnePassword.openModal()
    }
  }

  ngOnInit() {
  }

}
