import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteToMemory'
})
export class ByteToMemoryPipe implements PipeTransform {

  sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  transform(value): unknown {

    let l = 0
    let n = parseInt(value, 10) || 0;

    while(n >= 1024 && ++l){
        n = n/1024;
    }
    
    return(Math.round(n * 100) / 100 + ' ' + this.sizes[l]);
  }

}
